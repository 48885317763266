<template>
  <div>
    <detail-header :title="title"></detail-header>
    <div>
      <div class="main" style="padding-bottom:120px; padding-top:80px;">
        <div class="bubble">
        <cube-form
          :model="model" 
          :schema="schema"
          :immediate-validate="false"
          @validate="validateHandler"
          @submit="submitHandler" 
          @reset="resetHandler"
        ></cube-form>
              <div style="text-align: left; font-size:16px; padding-top: 10px;">幸福度:</div> &nbsp;
            <el-rate
    v-model="model.happiness"
    :colors="colors"  :max="10" :low-threshold="3" :high-threshold="7">
  </el-rate>
              <div style="text-align: left; font-size:16px; padding-top: 10px;">學習記錄:</div> &nbsp;
              <mavon-editor v-model="model.learningRec"  :ishljs="true" :boxShadow="false" @change="editorLearningRec" :editable="true" :subfield="false" :toolbars="toolbars" :defaultOpen="preview" style="z-index: 0;"></mavon-editor>
  
              <div style="text-align: left; font-size:16px; padding-top: 10px;">生活記錄:</div> &nbsp;
              <mavon-editor v-model="model.lifeRec"  :ishljs="true" :boxShadow="false" @change="editorLifeRec" :editable="true" :subfield="false" :toolbars="toolbars" :defaultOpen="preview" style="z-index: 0;"></mavon-editor>
<el-button el-button type="primary" @click="submitHandler" style="font-size: 15px;  line-height: 18px;">提交</el-button>
            <el-button type="warning" @click="resetHandler" style="font-size: 15px;  line-height: 18px;">重置</el-button>
      </div>
      </div>
            
    </div>
    <common-footer></common-footer>
  </div>
</template>

<script>
import { getDiaryDetail,update } from "@/api/getDiaryData.js";
import { getUserInfo } from "@/api/getUserData.js";
import CommonFooter from "@/components/CommonFooter.vue";
import DetailHeader from "@/components/Header.vue";
export default {
  //注册组件
  components: {
    DetailHeader,
    CommonFooter,
  },

  data() {
    return {
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'],

          preview: 'edit',
      toolbars: {
      bold: true, // 粗体
      italic: true, // 斜体
      header: true, // 标题
      underline: true, // 下划线
      strikethrough: false, // 中划线
      mark: true, // 标记
      superscript: false, // 上角标
      subscript: false, // 下角标
      quote: false, // 引用
      ol: true, // 有序列表
      ul: true, // 无序列表
      link: false, // 链接
      imagelink: false, // 图片链接
      code: false, // code
      table: false, // 表格
      fullscreen: false, // 全屏编辑
      readmodel: false, // 沉浸式阅读
      htmlcode: false, // 展示html源码
      help: false, // 帮助
      /* 1.3.5 */
      undo: true, // 上一步
      redo: true, // 下一步
      trash: false, // 清空
      save: false, // 保存（触发events中的save事件）
      /* 1.4.2 */
      navigation: false, // 导航目录
      /* 2.1.8 */
      alignleft: false, // 左对齐
      aligncenter: false, // 居中
      alignright: false, // 右对齐
      /* 2.2.1 */
      subfield: false, // 单双栏模式
      preview: true, // 预览
},
      title: "DIARY",
      validity: {},
      valid: undefined,
      model: {
        title: "",
        happiness: 3,
        learningRec: "",
        learningRecMd: "",
        lifeRec: "",
        lifeRecMd: "",
        createTime: "",
      },
      schema: {
        groups: [
          {
            legend: "需要修改嗎，當然闊以~",
            fields: [
              {
                type: "input",
                modelKey: "title",
                label: "標題：",
                props: {
                  placeholder: "请输入標題",
                },
                rules: {
                  required: true,
                },
                // validating when blur
                trigger: "blur",
                messages: {
                  required: "標題不能为空",
                },
              },
            ],
          },
        ],
      },
      info: {},
    };
  },

  computed: {
    getToken() {
      return this.$store.state.token;
    },
  },
  methods: {
    //获取视频详情
    async getDetail(id) {
      try {
        const result = await getDiaryDetail(this.$store.state.token, id);
        if (result.data.code == 0) {
          this.model.title = result.data.data.title;
          this.model.happiness = result.data.data.happiness;
          this.model.learningRec = result.data.data.learning_rec;
          this.model.learningRecMd = result.data.data.learning_rec_md;
          this.model.lifeRec = result.data.data.life_rec;
          this.model.lifeRecMd = result.data.data.life_rec_md;
          this.model.createTime = result.data.data.create_time;
        }
      } catch (error) {
        console.log(error);
      }
    },
    //获取用户信息
    async getInfo() {
      try {
        const result = await getUserInfo(this.getToken);
        if (result.data.code === 0) {
          this.info = result.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
        //markdown编辑器数据变化时触发
    editorLearningRec(value, render) {
      this.model.learningRec = value;
      this.model.learningRecMd = render;
    },
    editorLifeRec(value, render) {
      this.model.lifeRec = value;
      this.model.lifeRecMd = render;
    },

    validateHandler(result) {
      this.validity = result.validity;
      this.valid = result.valid;
      console.log(
        "validity",
        result.validity,
        result.valid,
        result.dirty,
        result.firstInvalidFieldIndex
      );
    },
    resetHandler(e) {
      this.model.title = "";
      this.model.happiness = "";
      this.model.learningRec = "";
      this.model.learningRecMd = "";
      this.model.lifeRec = "";
      this.model.lifeRecMd = "";
      console.log("reset", e);
    },
    submitHandler(e, model) {
      e.preventDefault();
      //调用注册接口
      update(
        this.$store.state.token,
        this.$route.query.id,
        this.model.title,
        this.model.happiness,
        this.model.learningRec,
        this.model.learningRecMd,
        this.model.lifeRec,
        this.model.lifeRecMd,
        this.model.createTime,
        this.info.id,
        this.info.head_img
      ).then((res) => {
        if (res.data.code === 0) {
          const toast = this.$createToast({
            txt: "修改成功啦<br/>10分鐘後查看喲",
            type: "correct",
            time: 1500,
          });
          toast.show();
        } else {
          const toast = this.$createToast({
            txt: "沒有成功提交",
            type: "error",
            time: 1500,
          });
          toast.show();
        }
      });
    },
    goTo(path) {
      this.$router.push(path);
    },
  },

  mounted() {
    //渲染完成后拿数据
    console.log(this.$route.query.id);
    this.getDetail(this.$route.query.id);
    if (this.getToken) {
      this.getInfo();
    }
  },
};
</script>

<style lang="scss" scoped>
.bubble {
  padding: 7px 10px;
  color: #333;
  background: #fff;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1);
  position: relative;
  margin: 0 20px 0 5px;
}
.main {
  padding: 50px 5% 0;
  text-align: center;
}

.login {
  display: block;
  background-color: #3bb149;
  border: none;
  outline: none;
  width: 80%;
  height: 40px;
  padding: 20px 5% 0;
  margin: 20px auto 0;
  color: #fff;
  border-radius: 20px;
}
</style>

<style lang="stylus" rel="stylesheet/stylus">
.slide-container-v {
  height: 265px;
  margin-bottom: 15px;
  transform: translateZ(0px);
  border-radius: 2px;
  overflow: hidden;
  box-shadow: 0 2px 9px #ddd;

  .cube-slide-dots {
    .my-dot {
      height: auto;
      font-size: 12px;
      background: none;

      &.active {
        color: #fc9153;
      }
    }
  }
}

.cube-slide-item > a > img {
  width: 100%;
  height: auto;
}
</style>
