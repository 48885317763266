import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../siteviews/Home/Home.vue'
import Diary from '../siteviews/Diary/Diary.vue'
import Personal from '../siteviews/Personal/Personal.vue'
import DiaryDetail from '../siteviews/DiaryDetail/DiaryDetail.vue'
import Login from '../siteviews/Login/Login.vue'
import Register from '../siteviews/Register/Register.vue'
import ChatRoom from '../siteviews/ChatRoom/ChatRoom.vue'
import Contact from '../siteviews/Contact/Contact.vue'
import Upload from '../siteviews/Upload/Upload.vue'
import Video from '../siteviews/Video/Video.vue'
import Audio from '../siteviews/Audio/Audio.vue'
import DiaryUpdate from '../siteviews/DiaryDetail/DiaryUpdate.vue'
import DiaryUpdatePC from '../siteviews/DiaryDetail/DiaryUpdatePC.vue'
import DiaryPC from '../siteviews/Diary/DiaryPC.vue'
import DiaryIndex from '../siteviews/Diary/index.vue'
import Calendar from '../siteviews/Calendar/Calendar.vue'
import DiaryOfDate from '../siteviews/DiaryOfDate/DiaryOfDate.vue'
import Profile from '../siteviews/Profile/Profile.vue'
import ToDoList from '../siteviews/ToDoList/ToDoList.vue'
import SiteLayout from '@/siteviews/Layout'
import NoneLayout from '@/siteviews/None'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: SiteLayout,
    children: [{
      path: '',
      component: Home,
    }]
  },
  {
    path: '/diary',
    component: NoneLayout,
    children: [{
      path: '',
      component: Diary,
    }],
    meta: { requiresAuth: true }
  },
  {
    path: '/personal',
    component: NoneLayout,
    children: [{
      path: '',
      component: Personal,
    }],
    meta: { requiresAuth: true }
  },
  {
    path: '/diaryDetail',
    component: NoneLayout,
    children: [{
      path: '',
      component: DiaryDetail,
    }],
    meta: { requiresAuth: true }
  },
  {
    path: "/login",
    component: NoneLayout,
    children: [{
      path: '',
      component: Login
    }],
  },
  {
    path: "/register",
    component: NoneLayout,
    children: [{
      path: '',
      component: Register
    }],

  }, {
    path: "/chatRoom",
    component: NoneLayout,
    children: [{
      path: '',
      component: ChatRoom,
    }],

    meta: { requiresAuth: true }
  }, {
    path: "/contact",
    component: NoneLayout,
    children: [{
      path: '',
      component: Contact,
    }],

    meta: { requiresAuth: true }
  },
  {
    path: "/upload",
    component: NoneLayout,
    children: [{
      path: '',
      component: Upload,
    }],

    meta: { requiresAuth: true }
  }, {
    path: "/video",
    component: NoneLayout,
    children: [{
      path: '',
      component: Video,
    }],

    meta: { requiresAuth: true }
  }, {
    path: "/audio",
    component: NoneLayout,
    children: [{
      path: '',
      component: Audio,
    }],

    meta: { requiresAuth: true }
  }, {
    path: "/diaryUpdate",
    component: NoneLayout,
    children: [{
      path: '',
      component: DiaryUpdate,
    }],

    meta: { requiresAuth: true }
  }, {
    path: "/diaryUpdatePC",
    component: NoneLayout,
    children: [{
      path: '',
      component: DiaryUpdatePC,
    }],

    meta: { requiresAuth: true }
  },
  {
    path: "/diaryPC",
    component: NoneLayout,
    children: [{
      path: '',
      component: DiaryPC,
    }],
    meta: { requiresAuth: true }
  },
  {
    path: "/diaryIndex",
    component: NoneLayout,
    children: [{
      path: '',
      component: DiaryIndex,
    }],

    meta: { requiresAuth: true }
  }, {
    path: "/calendar",
    component: NoneLayout,
    children: [{
      path: '',
      component: Calendar
    }],

  },
  {
    path: "/diaryOfDate",
    component: NoneLayout,
    children: [{
      path: '',
      component: DiaryOfDate
    }],

  },
  {
    path: "/profile",
    component: NoneLayout,
    children: [{
      path: '',
      component: Profile,
    }],

    meta: { requiresAuth: true }
  },
  {
    path: "/toDoList",
    component: NoneLayout,
    children: [{
      path: '',
      component: ToDoList,
    }],

    meta: { requiresAuth: true }
  },
  {
    path: "/found",
    name: 'found',
    component: () => import('@/siteviews/Music/found/found'),
    meta: {
      keepAlive: false,
      requiresAuth: true
    }, 
    hidden: true, 
  },
  {
    path: '/songlistdetail',
    name: 'songlistdetail',
    component: resolve => require(['@/siteviews/Music/songlist-detail/songlist-detail'], resolve),
    meta: {
      keepAlive: false,
      requiresAuth: true
    },
    hidden: true
  },
  {
    path: '/me',
    name: 'me',
    component: resolve => require(['@/siteviews/Music/me/me'], resolve),
    meta: {
      keepAlive: true,
      requiresAuth: true
    },
    hidden: true
  },
  {
    path:'/leaderboard',
    name:'leaderboard', 
    component: resolve => require(['@/siteviews/Music/leaderboard/leaderboard'], resolve),
    meta: {
      keepAlive:false,
      requiresAuth: true
    },
    hidden: true
  },
  {
    path: '/songlist',
    name: 'songlist',
    component: resolve => require(['@/siteviews/Music/songlist/songlist'], resolve),
    meta: {
      keepAlive: false,
      requiresAuth: true
    },
    hidden: true
  },
  {
    path:'/everydayrec',
    name:'everydayrec',
    component: resolve => require(['@/siteviews/Music/songlist-detail/everyday-rec'], resolve),
    meta: {
      keepAlive:false,
      requiresAuth: true
    },
    hidden: true
  },
  // {
  //   path: '*',
  //   component: (resolve) => require(['@/siteviews/404.vue'],resolve)
  // },

]

const router = new VueRouter({ 
  routes,
  mode: 'history',
  // base: '/dist/',
})

export default router
