<template>

  <div>
    <detail-header :title="title"></detail-header>

    <div style="padding-top:50px;" class="bubble">

          <user-card :user="user" />

                <account />

    </div>
    <common-footer></common-footer>

  </div>
</template>

<script>
// import { mapGetters } from 'vuex'
import UserCard from './components/UserCard'
// import Timeline from './components/Timeline'
import Account from './components/Account'
import DetailHeader from "@/components/Header.vue";
import CommonFooter from "@/components/CommonFooter";
import { getUserInfo} from "@/api/getUserData";


export default {
  
  name: 'Profile',
  components: { 
    UserCard, 
    // Timeline, 
    DetailHeader,
    CommonFooter,
    Account 
    },
  data() {
    return {
      title: "PROFILE",
      user: {
        name: "",
        avatar: ""
      },
      activeTab: 'timeline'
    }
  },
    computed: {
    getToken() {
      return this.$store.state.token;
    },
  },

  mounted() {
    this.getInfo();
  },
  methods: {
      async getInfo() {
      try {
        const result = await getUserInfo(this.getToken);
        if (result.data.code === 0) {
          console.log(result.data.data)
          this.user.name = result.data.data.name;
          this.user.avatar = result.data.data.head_img;
          console.log(this.user.name)
          console.log(this.user.avatar)
        }
      } catch (error) {
        console.log(error);
      }
    },
  }
}
</script>
<style>
  .bubble {
  padding: 7px 10px;
  color: #333;
  background: #fff;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1);
  position: relative;
  margin: 0 20px 0 20px;
}
</style>