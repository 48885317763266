import axios from '../request'

export const getToDoList = (token) => axios.get("/api/v1/pri/ToDoList/get_to_do_list", {
    headers: { "token": token }
});

export const saveToDo = (token, to_do_list) => axios.post("/api/v1/pri/ToDoList/save_to_do", {
    "to_do_list": to_do_list,
}, {
    headers: { "token": token }
})

export const getMindnode = (token) => axios.get("/api/v1/pri/Mindnode/get_mindnode", {
    headers: { "token": token }
});

export const saveMindnode = (token, mindnode) => axios.post("/api/v1/pri/Mindnode/save_mindnode", {
    "mindnode": mindnode,
}, {
    headers: { "token": token }
})
