<template>
<div>
    <detail-header :title="title"></detail-header>
  <div>
    <demo-questionnaire style="padding-top:43px;"
      slot="content"
      :tip="tip"
      :questions="questions"
      :submit="submit"
      @submit="submitHandler"
    />
    </div>
    <common-footer></common-footer>

    </div>
</template>

<script type="text/ecmascript-6">
import DetailHeader from "@/components/Header";
import CommonFooter from "@/components/CommonFooter.vue";
import DemoQuestionnaire from '../../components/questionnaire/questionnaire.vue'
export default {
  data() {
    return {
      tip: '提交圖片嗎，可以在上頁圖片庫看到喲，圖片穿壓縮至500K以下，麻煩啦~',
      title: 'PICTURE UPLOAD',
      questions: [
        
        {
          type: 'upload',
          model: 'upload',
          title: '上传',
          options: {
            action: '//119.3.129.176:8089/api/v1/pub/file/upload',
            max: 2
          },
          required: true
        },
      ]
    }
  },
  components: {
    DemoQuestionnaire,
        CommonFooter,
    DetailHeader,
  },
  methods: {
    submitHandler(model) {
      console.log('submit', model)
    }
  }
}
</script>
<style lang="stylus" scoped>
.cube-page
  &.page-questionnaire
    .wrapper
      .content
        margin: 0
</style>
