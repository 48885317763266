<template>
  <div>
    <div class="container">
      <div class="p_top">
        <div>
          <img :src="info.head_img || defaultHeadImg" alt="头像" @click="goTo('/profile')"/>
          <p v-if="getToken !== ''">{{ info.name }}</p>
        </div>
      </div>

      <cube-button :light="true" @click="goTo('/chatRoom')" class="content"
        >前往聊天室</cube-button
      >
      <cube-button :light="true" @click="goTo('/video')" class="content">圖片倉庫</cube-button>
      <!-- <cube-button :light="true" @click="goTo('/contact')" class="content">聯絡人</cube-button> -->
      <cube-button :light="true" @click="goTo('/audio')" class="content">冥想播放器</cube-button>
      <!-- <cube-button :light="true" @click="goTo('/found')" class="content">網易雲播放器</cube-button> -->
      <cube-button :light="true" @click="goTo('/toDoList')" class="content">代辦事項</cube-button>
      <!-- <a href="http://web.yoyolee2021.site/netmusic"><cube-button :light="true" class="content">網易雲播放器（外）</cube-button></a> -->

      <cube-button
        :primary="true"
        v-if="getToken === ''"
        @click="goTo('/login')" style="font-size: 20px;  line-height: 25px;"
        >立刻登錄</cube-button
      >
      <cube-button :primary="true" v-if="getToken !== ''" @click="signOut" style="font-size: 20px;line-height: 25px;"
        >退出登錄</cube-button
      >
    </div>
    <common-footer></common-footer>
  </div>
</template>

<script>
import CommonFooter from "@/components/CommonFooter";
import { getUserInfo} from "@/api/getUserData.js";
import defaultHeadImg from "@/assets/logo.png";

export default {
  components: {
    CommonFooter,
  },

  data() {
    return {
      info: {},
      defaultHeadImg: defaultHeadImg,
      customIndex: 1,
    };
  },

  computed: {
    getToken() {
      return this.$store.state.token;
    },
  },

  methods: {
    //获取用户信息
    async getInfo() {
      try {
        const result = await getUserInfo(this.getToken);
        if (result.data.code === 0) {
          this.info = result.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    //退出登录
    async signOut() {
      //清除token
      await this.$store.dispatch("clearToken");
      localStorage.removeItem("token");

      //刷新页面
      location.reload();
      
    },

    goTo(path) {
      this.$router.push(path);
    },
  },

  mounted() {
    if (this.getToken) {
      this.getInfo();
    }
  },
};
</script> 

<style lang="scss" scoped>
.container {
  // 顶部头像区域
  .p_top {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    background-color: #2c3f54;
    div {
      text-align: center;
      img {
        width: 90px;
        height: 90px;
        border-radius: 50px;
      }
      p {
        font-size: 30px;
        color: #fff;
        margin-top: 25px;
      }
    }
  }
}
// 退出登录
.green {
  display: block;
  background-color: #3bb149;
  border: none;
  outline: none;
  width: 80%;
  height: 40px;
  margin: 20px auto 0;
  color: #fff;
  border-radius: 20px;
}

.chatRoom {
  display: block;
  background-color: #3bb149;
  border: none;
  outline: none;
  width: 80%;
  height: 40px;
  margin: 20px auto 0;
  padding: 20px 5% 0;
  color: #fff;
  border-radius: 20px;
  text-align: center;
}
.content {
  margin-top: 8px;
  font-size: 40px;
  line-height: 45px;
  font-family: "Arial", "Microsoft YaHei", "黑体", "宋体", sans-serif;
  color: #504e4e;
  margin-top: 3px;
}
</style>