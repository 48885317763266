import axios from 'axios'

const service = axios.create({
    //url = base url + reqeust url
    // baseURL: "http://127.0.0.1:8089",
    // baseURL: "http://119.3.129.176:8089",
    // baseURL: "http://data.yoyolee2021.site",
    baseURL: "https://yoyolee-diary-backend-production.up.railway.app",

    //配置请求超时时间
    timeout: 5000
})

export default service;