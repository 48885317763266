import Vue from 'vue'
import './cube-ui'
import App from './App.vue'
import router from './router'
import store from './store'
import 'es6-promise/auto'
import Vant from 'vant';
import { Lazyload } from 'vant';
import 'vant/lib/index.css';
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import fastclick from 'fastclick'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'lib-flexible'

import VueLazyload from 'vue-lazyload'
import $ from 'jQuery';
import VideoPlayer from 'vue-video-player'
import mavonEditor from 'mavon-editor'
import 'normalize.css/normalize.css' // A modern alternative to CSS resets
import 'mavon-editor/dist/css/index.css'

import Video from 'video.js'
import 'video.js/dist/video-js.css'

Vue.prototype.$video = Video

Vue.use(VueLazyload, {
  loading: require('@/common/image/default.jpg'),
  error: require('@/common/image/error.jpg')
})

Vue.use(VueAwesomeSwiper, /* { default global options } */)
fastclick.attach(document.body)

import '@/common/stylus/index.styl'
import '@/common/fonts/iconfont.css'
import 'swiper/swiper-bundle.css'

require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)

window.jQuery = $;
window.$ = $;
Vue.use(ElementUI);

Vue.use(Vant)
Vue.use(Lazyload)
// use
Vue.use(mavonEditor)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


//路由拦截，拦截全部路由，每次操作路由都是被拦截进行判断

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  //筛选需要传token的路由，匹配route里面需要登录的路径，如果匹配到就是true
  if (to.matched.some(record => record.meta.requiresAuth)) {
    //根据token是否有，判断是否需要调到登录页面
    if (token) {
      next()
    } else {
      next({ path: '/login' })
    }
  } else {
    next();
  }
})
// import Router from 'vue-router'
// const routerPush = Router.prototype.push
// Router.prototype.push = function push(location) {
// return routerPush.call(this, location).catch(error=> error)
// }