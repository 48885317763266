<template>
  <div>
    <detail-header :title="title"></detail-header>
    <div slot="content" style="padding-bottom:120px; padding-top:80px;">
      <div>
        <div class="main" >
          <div class="bubble">
            <cube-form
              :model="model"
              :schema="schema"
              :immediate-validate="false"
              @validate="validateHandler"
              @submit="submitHandler"
              @reset="resetHandler"
            ></cube-form>
            <div style="text-align: left; font-size: 16px; padding-top: 10px">
              幸福度:
            </div>
            &nbsp;
            <el-rate
              v-model="model.happiness"
              :colors="colors"
              :max="10"
              :low-threshold="3"
              :high-threshold="7"
            >
            </el-rate>
            <div style="text-align: left; font-size: 16px; padding-top: 10px">
              學習記錄:
            </div>
            &nbsp;
            <mavon-editor
              v-model="model.learningRec"
              :ishljs="true"
              :boxShadow="false"
              @change="editorLearningRec"
              :editable="true"
              :subfield="false"
              :toolbars="toolbars"
              :defaultOpen="preview"
              style="z-index: 0"
            ></mavon-editor>

            <div style="text-align: left; font-size: 16px; padding-top: 10px">
              生活記錄:
            </div>
            &nbsp;
            <mavon-editor
              v-model="model.lifeRec"
              :ishljs="true"
              :boxShadow="false"
              @change="editorLifeRec"
              :editable="true"
              :subfield="false"
              :toolbars="toolbars"
              :defaultOpen="preview"
              style="z-index: 0"
            ></mavon-editor>
            <el-button el-button type="primary" @click="submitHandler" style="font-size: 15px;  line-height: 18px;"
              >提交</el-button
            >
            <el-button type="warning" @click="resetHandler" style="font-size: 15px;  line-height: 18px;">重置</el-button>
          </div>
        </div>
      </div>
    </div>
    <common-footer></common-footer>
  </div>
</template>

<script>
import { record } from "@/api/getDiaryData.js";
import DetailHeader from "@/components/Header";
import CommonFooter from "@/components/CommonFooter.vue";
import { getUserInfo } from "@/api/getUserData.js";

export default {
  //注册组件
  components: {
    CommonFooter,
    DetailHeader,
  },

  data() {
    return {
      colors: ["#99A9BF", "#F7BA2A", "#FF9900"],

      preview: "edit",
      toolbars: {
        bold: true, // 粗体
        italic: true, // 斜体
        header: true, // 标题
        underline: true, // 下划线
        strikethrough: false, // 中划线
        mark: true, // 标记
        superscript: false, // 上角标
        subscript: false, // 下角标
        quote: false, // 引用
        ol: true, // 有序列表
        ul: true, // 无序列表
        link: false, // 链接
        imagelink: false, // 图片链接
        code: false, // code
        table: false, // 表格
        fullscreen: false, // 全屏编辑
        readmodel: false, // 沉浸式阅读
        htmlcode: false, // 展示html源码
        help: false, // 帮助
        /* 1.3.5 */
        undo: true, // 上一步
        redo: true, // 下一步
        trash: false, // 清空
        save: false, // 保存（触发events中的save事件）
        /* 1.4.2 */
        navigation: false, // 导航目录
        /* 2.1.8 */
        alignleft: false, // 左对齐
        aligncenter: false, // 居中
        alignright: false, // 右对齐
        /* 2.2.1 */
        subfield: false, // 单双栏模式
        preview: true, // 预览
      },
      title: "DIARY",
      validity: {},
      valid: undefined,
      model: {
        title: "",
        happiness: 5,
        learningRec: "",
        learningRecMd: "",
        lifeRec: "",
        lifeRecMd: "",
      },
      schema: {
        groups: [
          {
            legend: "好心情，來記錄下來吧~",
            fields: [
              {
                type: "input",
                modelKey: "title",
                label: "標題：",
                props: {
                  placeholder: "请输入標題",
                },
                rules: {
                  required: true,
                },
                // validating when blur
                trigger: "blur",
                messages: {
                  required: "標題不能为空",
                },
              },
            ],
          },
        ],
      },
      info: {},
    };
  },

  computed: {
    getToken() {
      return this.$store.state.token;
    },
  },
  methods: {
    //markdown编辑器数据变化时触发
    editorLearningRec(value, render) {
      this.model.learningRec = value;
      this.model.learningRecMd = render;
    },
    editorLifeRec(value, render) {
      this.model.lifeRec = value;
      this.model.lifeRecMd = render;
    },
    //获取用户信息
    async getInfo() {
      try {
        const result = await getUserInfo(this.getToken);
        if (result.data.code === 0) {
          this.info = result.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    validateHandler(result) {
      this.validity = result.validity;
      this.valid = result.valid;
      console.log(
        "validity",
        result.validity,
        result.valid,
        result.dirty,
        result.firstInvalidFieldIndex
      );
    },
    resetHandler(e) {
      this.model.title = "";
      this.model.happiness = "";
      this.model.learningRec = "";
      this.model.learningRecMd = "";
      this.model.lifeRec = "";
      this.model.lifeRecMd = "";
      console.log("reset", e);
    },
    submitHandler(e, model) {
      e.preventDefault();
      //调用注册接口
      record(
        this.$store.state.token,
        this.model.title,
        this.model.happiness,
        this.model.learningRec,
        this.model.learningRecMd,
        this.model.lifeRec,
        this.model.learningRecMd,
        this.info.id,
        this.info.head_img
      ).then((res) => {
        if (res.data.code === 0) {
          const toast = this.$createToast({
            txt: "好心情已經記錄下來啦<br/>請查收",
            type: "correct",
            time: 1500,
          });
          toast.show();
        } else {
          const toast = this.$createToast({
            txt: "沒有成功提交",
            type: "error",
            time: 1500,
          });
          toast.show();
        }
      });
    },
    goTo(path) {
      this.$router.push(path);
    },
  },
  mounted() {
    if (this.getToken) {
      this.getInfo();
    }
  },
};
</script>

 <style lang="scss" scoped>
.main {
  padding: 50px 5% 0;
  text-align: center;
}
.header {
  display: flex; //flex左右布局
  background-color: #07111b;
  padding: 10px 20px;
  color: #fff;
}
.login {
  display: block;
  background-color: #3bb149;
  border: none;
  outline: none;
  width: 80%;
  height: 40px;
  margin: 20px auto 0;
  padding: 20px 5% 0;
  color: #fff;
  border-radius: 20px;
}
</style>
<style lang="stylus" rel="stylesheet/stylus">
.slide-container-v {
  height: 265px;
  margin-bottom: 15px;
  transform: translateZ(0px);
  border-radius: 2px;
  overflow: hidden;
  box-shadow: 0 2px 9px #ddd;

  .cube-slide-dots {
    .my-dot {
      height: auto;
      font-size: 12px;
      background: none;

      &.active {
        color: #fc9153;
      }
    }
  }
}

.cube-slide-item > a > img {
  width: 100%;
  height: auto;
}
</style>