<template>
  <div>
    <detail-header :title="title"></detail-header>
    <div class="main" style="padding-bottom:120px; padding-top:80px;">
      <div
        class="avatar"
        :style="{
          backgroundImage: 'url(' + (this.model.userImg || '') + ')',
        }"
      >
        <br /><br />
        <p class="bold">{{ this.model.userId }}</p>
      </div>

      <div class="bubble">
        <div class="content">
          <span class="bold">標題:</span> &nbsp;{{ this.model.title }}
        </div>

        <div class="content">
          <span class="bold">幸福度:</span> &nbsp;
          <el-rate
            v-model="model.happiness"
            :colors="colors"
            :max="10"
            :low-threshold="3"
            :high-threshold="7"
            :disabled="true"
          >
          </el-rate>
        </div>
        <div class="content">
          <span class="bold">創建時間:</span> &nbsp;{{ this.model.createTime }}
        </div>
        <div class="content">
          <span class="bold">日記介紹:</span> &nbsp;
          <mavon-editor
            v-model="model.description"
            :ishljs="true"
            :editable="false"
            :subfield="false"
            :toolbars="toolbars"
            :defaultOpen="preview"
            style="min-height: 150px; min-width: 150px; z-index: 0"
          ></mavon-editor>
        </div>
      </div>
      <div style="text-align: right">
        <el-button type="primary" @click="goTo('/diary')" icon="el-icon-edit" style="font-size:15px; line-height:20px;"
          >手機端</el-button
        >
        <el-button type="primary" @click="goTo('/diaryPC')" icon="el-icon-edit" style="font-size:15px; line-height:20px;"
          >電腦端</el-button
        >
      </div>
    </div>
    <common-footer></common-footer>
  </div>
</template>

<script>
import { getUserInfo } from "@/api/getUserData.js";
import { getImageList } from "@/api/getData.js";
import defaultHeadImg from "@/assets/logo.png";
import DetailHeader from "@/components/Header.vue";
import CommonFooter from "@/components/CommonFooter";

export default {
  components: {
    CommonFooter,
    DetailHeader,
  },

  data() {
    return {
      colors: ["#99A9BF", "#F7BA2A", "#FF9900"],

      title: "TYPE OF DIARY",
      preview: "preview",
      info: {},
      defaultHeadImg: defaultHeadImg,
      customIndex: 1,
      imgs: [],
      model: {
        title: "开始写日记吧",
        happiness: 5,
        id: 0,
        description:
          "# 一起來寫日記\n我們有電腦端和手機端\n## 手機端\n1.重要功能都有啦，也可以支持主要的markdown格式了喲\n## 電腦端\n1.在這裡你們可以==標註==自己覺得始終點的事務\n2. ~~給不需要、或者不重要的內容~~劃去\n3.也可以插入表格\n\n|想購買的東西|價格|數量|總價|\n|-|-|-|-|\n|可樂|2.5|4|10|\n> 還有蠻多的功能等著你來發掘喲",
        createTime: "2021/09/01 00:00:00",
        userId: 0,
        userImg: "http://119.3.129.176/images/avatar10.jpg",
      },
      toolbars: {
        bold: false, // 粗体
        italic: false, // 斜体
        header: false, // 标题
        underline: false, // 下划线
        strikethrough: false, // 中划线
        mark: false, // 标记
        superscript: false, // 上角标
        subscript: false, // 下角标
        quote: false, // 引用
        ol: false, // 有序列表
        ul: false, // 无序列表
        link: false, // 链接
        imagelink: false, // 图片链接
        code: false, // code
        table: false, // 表格
        fullscreen: false, // 全屏编辑
        readmodel: false, // 沉浸式阅读
        htmlcode: false, // 展示html源码
        help: false, // 帮助
        /* 1.3.5 */
        undo: false, // 上一步
        redo: false, // 下一步
        trash: false, // 清空
        save: false, // 保存（触发events中的save事件）
        /* 1.4.2 */
        navigation: false, // 导航目录
        /* 2.1.8 */
        alignleft: false, // 左对齐
        aligncenter: false, // 居中
        alignright: false, // 右对齐
        /* 2.2.1 */
        subfield: false, // 单双栏模式
        preview: true, // 预览
      },
    };
  },

  computed: {
    getToken() {
      return this.$store.state.token;
    },
  },

  methods: {
    //获取用户信息
    async getInfo() {
      try {
        const result = await getUserInfo(this.getToken);
        if (result.data.code === 0) {
          this.info = result.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    //退出登录
    async signOut() {
      //清除token
      await this.$store.dispatch("clearToken");
      localStorage.removeItem("token");

      //刷新页面
      location.reload();
    },
    showCustomImagePreview() {
      this.$createImagePreview(
        {
          imgs: this.imgs,
          initialIndex: this.customIndex,
          loop: false,
          speed: 500,
          onChange: (i) => {
            this.customIndex = i;
          },
          onHide: () => {
            console.log("hide");
          },
        },
        (h) => {
          return h(
            "div",
            {
              class: {
                "image-preview-custom-header": true,
              },
              slot: "header",
            },
            this.customIndex + 1
          );
        }
      ).show();
    },

    goTo(path) {
      this.$router.push(path);
    },
    async getImage() {
      try {
        const result = await getImageList();
        console.log(result);
        console.log(result.data.code === 0);
        if (result.data.code === 0) {
          this.imgs = result.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },

  mounted() {
    this.getImage();
    if (this.getToken) {
      this.getInfo();
    }
    $(document).ready(function () {
      var tags_p = $("div");
      tags_p.each(function () {
        var x = 6;
        var y = 0;
        var rand = parseInt(Math.random() * (x - y - 1) + y);
        // console.log($(this).attr("class"));
        if ($(this).attr("class") === "main") {
          $(this).addClass("tags" + rand);
        }
      });
    });
  },
};
</script>

<style lang="scss">
.bold {
  font-weight: bold;
}
.main {
  padding: 50px 5% 80px;
  // text-align: center;
}

.content {
  margin-top: 8px;
  font-size: 30px;
  line-height: 35px;
  font-family: "Arial", "Microsoft YaHei", "黑体", "宋体", sans-serif;
  color: #000000;
  // font-style: italic;
  margin-top: 3px;
}

.bubble {
  padding: 7px 10px;
  color: #333;
  background: #fff;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1);
  position: relative;
  margin: 0 20px 0 5px;
}

.avatar {
  border-radius: 50%;
  margin-left: 30px;
  margin-right: 12px;
  // min-width: 48px;
  width: 75px;
  height: 75px;
  background-size: cover;
  background-image: url("http://119.3.129.176/images/avatar10.jpg");
  outline: none;
}

.tags0 {
  background-image: url("../../common/images/avatar0.png");
}

.tags1 {
  background-image: url("../../common/images/avatar1.png");
}

.tags2 {
  background-image: url("../../common/images/avatar2.png");
}

.tags3 {
  background-image: url("../../common/images/avatar3.png");
}

.tags4 {
  background-image: url("../../common/images/avatar4.png");
}

.tags5 {
  background-image: url("../../common/images/avatar5.png");
}

.bubble {
  padding: 7px 10px;
  color: #333;
  background: #fff;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1);
  position: relative;
  margin: 0 20px 0 5px;
}

.slide-container-v {
  height: 265px;
  margin-bottom: 15px;
  transform: translateZ(0px);
  border-radius: 2px;
  overflow: hidden;
  box-shadow: 0 2px 9px #ddd;

  .cube-slide-dots {
    .my-dot {
      height: auto;
      font-size: 12px;
      background: none;

      &.active {
        color: #fc9153;
      }
    }
  }
}

.cube-slide-item > a > img {
  width: 100%;
  height: auto;
}
// .chatRoom {
//   display: block;
//   background-color: #3bb149;
//   border: none;
//   outline: none;
//   width: 80%;
//   height: 40px;
//   margin: 20px auto 0;
//   padding: 20px 5% 0;
//   color: #fff;
//   border-radius: 20px;
//   text-align: center;
// }
</style>