<template>
  <div>
    <div class="main">
      <cube-form :model="model" @submit="submitHandler">
        <cube-form-group>
          <!--名称-->
          <cube-form-item :field="fields[0]"></cube-form-item>
          <!--手机号-->
          <cube-form-item :field="fields[1]"></cube-form-item>
          <!--密码-->
          <cube-form-item :field="fields[2]"></cube-form-item>
        </cube-form-group>

        <cube-form-group>
          <cube-button type="submit">註冊</cube-button>
        </cube-form-group>
      </cube-form>
      <router-link to="/login" class="reg">登錄</router-link>
    </div>
    <common-footer></common-footer>
  </div>
</template>

<script>
//注册接口
import CommonFooter from "@/components/CommonFooter";

import { registerApi } from "@/api/getUserData.js";
export default {
  components: {
    CommonFooter,
  },
  data() {
    return {
      model: {
        phoneValue: "",
        pwdValue: "",
        nameValue: "",
      },
      fields: [
        {
          type: "input",
          modelKey: "nameValue",
          label: "名稱",
          props: {
            placeholder: "請輸入名稱",
          },
          rules: {
            required: true,
          },
          messages: {
            required: "名稱不能為空",
          },
        },
        {
          type: "input",
          modelKey: "phoneValue",
          label: "手機號",
          props: {
            placeholder: "請輸入手機號",
          },
          rules: {
            required: true,
          },
          messages: {
            required: "手機號不能為空",
          },
        },
        {
          type: "input",
          modelKey: "pwdValue",
          label: "密碼",
          props: {
            placeholder: "請輸入密碼",
            type: "password",
            eye: {
              open: false,
            },
          },
          rules: {
            required: true,
          },
          messages: {
            required: "密碼不能為空",
          },
        },
      ],
    };
  },
  methods: {
    submitHandler(e, model) {
      e.preventDefault();
      //调用注册接口
      registerApi(model.phoneValue, model.pwdValue, model.nameValue).then(
        (res) => {
          if (res.data.code === 0) {
            const toast = this.$createToast({
              txt: "註冊成功",
              type: "correct",
              time: 1500,
            });
            toast.show();
          }
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  padding: 50px 5% 0;
  text-align: center;
}
//注册
.cube-btn {
  margin-top: 20px;
}
// 登录
.reg {
  display: inline-block;
  margin-top: 30px;
  font-size: 18px;
}
</style>