const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  // token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  // qiNiuUploadApi: state => state.api.qiNiuUploadApi,
  swaggerApi: state => state.api.swaggerApi,
  currentSong: state => state.playlist[state.currentIndex] || {},
  playing: state => state.playing,
  playlist: state => state.playlist,
  modeType: state => state.modeType,
  isLogin: state => state.isLogin,

  phone: state => state.phone,
  userId: state => state.userId,
  username: state => state.username,
  avatarUrl: state => state.avatarUrl,
  isLogin: state => state.isLogin,
  videoId: state => state.videoId,
  songlistId: state => state.songlistId,
  radioId: state => state.radioId,
  commentType: state => state.commentType,
  otherUserId: state => state.otherUserId,
  singerId: state => state.singerId,
  playing: state => state.playing,
  fullScreen: state => state.fullScreen,
  playlist: state => state.playlist,
  sequenceList: state => state.sequenceList,
  mode: state => state.mode,
  currentIndex: state => state.currentIndex,
  disc: state => state.disc,
  topList: state => state.topList,
  modeType: state => state.modeType,
  searchHistory: state => state.searchHistory,
  playHistory: state => state.playHistory,
  favoriteList: state => state.favoriteList,
  isTop: state => state.isTop
}
export default getters
