import Vue from 'vue'
import Vuex from 'vuex'
import event from './event';
import theme from './theme';
import getters from './getters'
import app from './modules/app'
import api from './modules/api'
import settings from './modules/settings'
import * as actions from './actions'
import mutations from './mutations'
import state from './state'

Vue.use(Vuex)


export default new Vuex.Store({
  // actions: {

  // },
  // state: {

  // },
  //同步修改state里面的值
  // mutations: {

  // },
  //异步调用mutations里面的方法
  //contxt.commit 利用上下文触发mutations某个方法
  // vue代码里面 this.$store.dispatch触发action里面的定义的方法
  modules: {
    event,
    theme,
    app,
    api,
    settings,
  },
  actions,
  getters,
  mutations,
  state,
})
