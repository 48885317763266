<template>
  <div>
      <detail-header :title="title"></detail-header>

    <div slot="content" >
      <div class="view-wrapper">
        <div class="index-list-wrapper custom">
          <cube-index-list :data="singerData">
            <cube-index-list-group
              v-for="(group, index) in singerData"
              :key="index"
              :group="group"
            >
              <cube-index-list-item
                v-for="(item, index) in group.items"
                :key="index"
                :item="item"
                @select="selectItem"
              >
                <div class="custom-item">
                  <img class="avatar" v-lazy="item.avatar" />
                  <span class="name">{{ item.name }}</span>
                </div>
              </cube-index-list-item>
            </cube-index-list-group >
            <span class="custom-nav-item" slot="nav-item" slot-scope="props">{{
              props.item
            }}</span>
          </cube-index-list>
          
      </div>
    </div>
    
        </div>
    <common-footer></common-footer>

  </div>
</template>
<script>
import CubePage from "@/components/cube-page.vue";
// import singerData from "./Components/singer.json";
import cubeIndexListItem from "@/components/index-list/index-list-item.vue";
import cubeIndexListGroup from "@/components/index-list/index-list-group.vue";
import cubeIndexList from "@/components/index-list/index-list.vue";
import CommonFooter from "@/components/CommonFooter.vue";
import DetailHeader from '@/components/Header';
import {getContactList} from "@/api/getData.js";


export default {
  components: {
    cubeIndexListItem,
    cubeIndexListGroup,
    cubeIndexList,
    CubePage,
    CommonFooter,
    DetailHeader
  },
  data() {
    return {
      title:"CONTACT",
      singerData: [],
    };
  },
  methods: {
    selectItem(item) {
      console.log(item.name);
    },
    clickTitle(title) {
      console.log(title);
    },
    async getSinger() {
      try {
        const result = await getContactList();
        console.log(result);
        console.log(result.data.code === 0);
        if (result.data.code === 0) {
          this.singerData = result.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.getSinger();
  },
};
</script>
<style lang="stylus" rel="stylesheet/stylus" scoped>
  @require "../../../src/common/stylus/variable.styl"

  .view-wrapper
    position: fixed
    top: 34px
    left: 0
    bottom: 0
    width: 100%
    .index-list-wrapper
      height: 100%
      width: 100%
      margin: 0 auto
      overflow: hidden
      &.custom
        .cube-index-list-content
          background-color: #222
          color: #909090
        .cube-index-list-group
          padding-bottom: 30px
        .cube-index-list-anchor
          background-color: #333
          height: 30px
          line-height: 30px
          padding: 0 0 0 20px
        .custom-item
          display: flex
          align-items: center
          padding: 20px 0 0 30px
          .avatar
            width: 90px
            height: 90px
            border-radius: 50%
          .name
            margin-left: 20px
            color: $color-text-l
            font-size: 30px
        .cube-index-list-nav
          padding: 20px 0
          border-radius: 10px
          background: rgba(0,0,0,.3)
          >ul
            >li
              padding: 3px
              &.active
                .custom-nav-item
                  color: #ffcd32
        .custom-nav-item
          font-size: 12px
          color: #909090
</style>
