<template>
  <div>
    <!-- <div class="tips">試試看點擊照片喲~</div> -->
    <div class="imgs-container">
      <img
        :src="img"
        v-for="(img, index) in imgs"
        :key="img"
        @click="handleImgsClick(index)"
      />
    </div>
  </div>
</template>

<script>
import { getImageList } from "@/api/getData.js";

export default {
  props: {
    imgs: {
      type: Array,
      required: true,
    },
  },
  methods: {
    handleImgsClick(index) {
      this.initialIndex = index;
      const params = {
        $props: {
          imgs: this.imgs,
          initialIndex: "initialIndex",
          loop: false,
        },
        $events: {
          change: (i) => {
            this.initialIndex = i;
          },
        },
      };
      this.$createImagePreview({ ...params }).show();
    },
    async getImage() {
      try {
        const result = await getImageList();
        console.log(result);
        console.log(result.data.code === 0);
        if (result.data.code === 0) {
          this.imgs = result.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped >
.video-js .vjs-icon-placeholder {
  width: 100%;
  height: 100%;
  display: block;
}
</style>

<style lang="stylus" rel="stylesheet/stylus">
.imgs-container {
  margin-top: 50px;

  > img {
    max-width: 100px;
    height: 67px;
    margin: 0 10px 16px 10px;
    border-radius: 4px;
  }
}
</style>