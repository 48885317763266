var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('detail-header',{attrs:{"title":_vm.title}}),[_c('div',{staticStyle:{"padding-bottom":"160px","padding-top":"60px"},attrs:{"slot":"content"},slot:"content"},[_c('el-table',{staticClass:"audio-js",staticStyle:{"width":"100%"},attrs:{"data":_vm.list,"stripe":""}},[_c('el-table-column',{attrs:{"label":"","width":"60","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{on:{"click":function($event){return _vm.handlerPlay(row.id)}}},[_c('span',[_vm._v(_vm._s(row.id))])])]}}])}),_c('el-table-column',{attrs:{"label":"歌曲標題"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{on:{"click":function($event){return _vm.handlerPlay(row.id)}}},[_c('span',[_vm._v(_vm._s(row.name))])])]}}])}),_c('el-table-column',{attrs:{"label":"","width":"60","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"music-btn"},[_c('i',{class:_vm.player[row.id] && _vm.player[row.id].play
                    ? 'el-icon-video-pause'
                    : 'el-icon-video-play',on:{"click":function($event){return _vm.handlerPlay(row.id)}}})])]}}])})],1)],1)],_c('div',{staticClass:"bubble"},[_c('span',[_vm._v("當前歌曲："+_vm._s(_vm.list[_vm.index].name))]),_c('el-slider',{attrs:{"format-tooltip":_vm.formatTooltip,"min":_vm.sliderMin,"max":_vm.sliderMax},on:{"change":_vm.spliderSelect},model:{value:(_vm.sliderVal),callback:function ($$v) {_vm.sliderVal=$$v},expression:"sliderVal"}}),_c('div',[_c('div',{staticClass:"operators"},[_c('div',{staticStyle:{"width":"80px","font-size":"25px"}},[_vm._v(_vm._s(_vm.currentTime))]),_c('div',{staticClass:"icon i-left;"},[_c('i',{staticClass:"iconfont icon-xiayishou1",on:{"click":function($event){return _vm.musicPlay('pre')}}})]),_c('div',{staticClass:"icon i-center"},[_c('i',{staticClass:"iconfont",class:_vm.play ? 'icon-zanting' : 'icon-bofang',on:{"click":function($event){return _vm.musicPlay('play')}}})]),_c('div',{staticClass:"icon i-right"},[_c('i',{staticClass:"icon-next iconfont icon-xiayishou",on:{"click":function($event){return _vm.musicPlay('next')}}})]),_c('div',{staticStyle:{"width":"80px","font-size":"25px"}},[_vm._v(_vm._s(_vm.duration))])])])],1),_c('audio',{ref:"singeBox",staticStyle:{"opacity":"0","max-width":"3px","_width":"expression(this.width > 1 ? “1px”: this.width)","max-height":"1px"},attrs:{"controls":""}}),_c('common-footer')],2)}
var staticRenderFns = []

export { render, staticRenderFns }