<template>
    <section class="event-all" :class="{'event-all-show':isShow}" style="padding-top:37px;">
        <div class="table-box" style="padding-top:37px;">
            <div class="edit-input" :class="{'edit-input-show': is_edit}" style="padding-top:37px;">
                <input type="text" v-model="info.content" ref="content" @keyup.enter="editData">
                <button @click="editData">確定</button>
            </div>
            <div class="screen-box">
                <div class="div-select" :class="{'active': active}">
                    <div class="div-value" @click="active = !active" >{{getTypeName}}</div>
                    <div class="div-select-body" >
                        <div class="div-option" @click="doSelect(0)"  style="font-size:15px;">篩選類型</div>
                        <div class="div-option" @click="doSelect(1)"  style="font-size:15px;">未完成</div>
                        <div class="div-option" @click="doSelect(2)"  style="font-size:15px;">已完成</div>
                        <div class="div-option" @click="doSelect(3)"  style="font-size:15px;">已取消</div>
                    </div>
                </div>
                <input type="text" class="div-search" v-model="screen_title" placeholder="篩選關鍵詞">
            </div>
            <table class="event-table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th style="font-size:20px;">所有事項</th>
                        <th width="" style="font-size:20px;">類型</th>
                        <th width="" style="font-size:20px;">操作</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(value, index) in notapad" :key="index">
                        <td align="center" style="font-size:20px;">{{index + 1}}</td>
                        <td style="font-size:20px;">{{value.content}}</td>
                        <td align="center" style="font-size:20px;">{{getType(value.type)}}</td>
                        <td align="center"><button @click="showInput(index)">編輯</button>
                            <button class="del-btn" @click="showDialog(index,value.id)">刪除</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
</template>

<script>
    export default {
        data: function(){
            return {
                is_edit: false,
                active: false,
                screen_type: 0,
                screen_title: '',
                info:{
                    content: '',
                    id: 0,
                    index: 0
                }
            }
        },
        props:['isShow'],
        computed:{
            notapad(){
                var self = this;
                return self.$store.getters.getEventList.filter(function(d){
                    if(self.screen_type !== 0 && self.screen_title === ''){
                        if( d.type === self.screen_type ){
                            return d;
                        }
                    }else if(self.screen_type !== 0 && self.screen_title !== ''){
                        if( d.type === self.screen_type && d.content.indexOf(self.screen_title) !== -1){
                            return d;
                        }
                    }else if(self.screen_type === 0 && self.screen_title !== ''){
                        if(d.content.indexOf(self.screen_title) !== -1){
                            return d;
                        }
                    }else{
                        return d;
                    }
                });
            },
            getTypeName(){
                const self = this;
                return self.getType(self.screen_type) || '篩選類型';
            }
        },
        methods:{
            doSelect(type){
                this.screen_type = type;
                this.active = false;
            },
            getType(type){
                let str = '';
                switch(type) {
                    case 1:
                        str = '未完成';
                        break;
                    case 2:
                        str = '已完成';
                        break;
                    case 3:
                        str = '已取消';
                        break;
                }
                return str;
            },
            showDialog(index,id){
                this.$emit('deldialog',index,id);
            },
            showInput(index){
                this.is_edit = true;
                this.info = {
                    index : index,
                    content : this.notapad[index].content,
                    id : this.notapad[index].id
                }
                this.$refs.content.focus();
            },
            editData(){
                this.info.content = this.info.content.trim();
                if(this.info.content){
                    this.$store.dispatch('editevent',this.info);
                    this.is_edit = false;
                    this.$toast('保存成功');
                }
            },
            closeTable(){
                this.$emit('close');
            }
        }
    }
</script>

<style lang="scss" rel="stylesheet/scss" >
    .event-all{
        position: absolute;
        left:0px;
        top:70px;
        right:0;
        bottom:0;
        transform: translateX(-100%);
        transition: transform .5s;
        overflow-Y: scroll;
        background: #fff;
        &.event-all-show{
            transform: translateX(0);
        }
        &::-webkit-scrollbar{
            width:0;
        }
        .table-box{
            width:100%;
            max-width:1000px;
            margin: 20px auto 70px;
            .event-table{
                width:100%;
                padding:0;
                border:{
                    left:1px solid #eee;
                    top:1px solid #eee;
                }
                border-spacing: 0px;
                td,th{
                    position: relative;
                    height: 40px;
                    min-width: 0;
                    padding:5px 10px;
                    box-sizing: border-box;
                    text-overflow: ellipsis;
                    vertical-align: middle;
                    border:{
                        right:1px solid #eee;
                        bottom:1px solid #eee;
                    }
                }
                button{
                    padding:3px 7px;
                    font-size: 30px;
                    color: #fff;
                    border:0;
                    margin: 0 3px 3px 0;
                    &.del-btn{
                        background: #f57067 !important;
                    }
                }
            }
            .edit-input{
                position: fixed;
                top:0;
                width:100%;
                max-width: 1000px;
                margin:auto;
                height:60px;
                padding:10px 100px 10px 10px;
                box-sizing: border-box;
                z-index:10;
                transform: translateY(-260px);
                transition: transform .3s;
                background: #f3f3f3;
                border:1px solid #eee;
                border-radius:4px;
                &.edit-input-show{
                    transform: translateY(0);
                }
                input{
                    float: left;
                    width:100%;
                    height:40px;
                    padding:5px 10px;
                    box-sizing: border-box;
                    border: 1px solid #ddd;
                }
                button{
                    position: absolute;
                    right: 10px;
                    width:80px;
                    height:40px;
                    color: #fff;
                    border:0;
                }
            }
            .screen-box{
                position: relative;
                padding: 0 0 10px 95px;
                height:40px;
                .div-select{
                    position: absolute;
                    left:0;
                    top:0;
                    width:180px;
                    height:40px;
                    padding:0 10px;
                    box-sizing: border-box;
                    border:1px solid #eee;
                    font-size:35px;
                    color: #999;
                    cursor: pointer;
                    &:after{
                        position: absolute;
                        right:6px;
                        top:12px;
                        width:6px;
                        height:6px;
                        content: '';
                        border:{
                            right:1px solid #999;
                            bottom: 1px solid #999;
                        }
                        transform: rotate(45deg);
                    }
                    &.active{
                        .div-select-body{
                            height:122px;
                            border:1px solid #eee;
                            box-shadow: 0 0 1px #ddd;
                        }
                    }
                }
                .div-value{
                    line-height: 35px;
                    width: 160px;
                }
                .div-select-body{
                    position: absolute;
                    left:0;
                    top:38px;
                    width:160px;
                    height:0;
                    padding-left: 10px;
                    line-height: 30px;
                    box-sizing: border-box;
                    overflow: hidden;
                    z-index:10;
                    box-shadow: none;
                    border: none;
                    border-radius: 3px;
                    background: #fff;
                    transition: all .3s;
                }
                .div-search{
                    width:100%;
                    height:50px;
                    line-height: 45px;
                    padding:15px 100px;
                    box-sizing: border-box;
                    border:1px solid #eee;
                    font:{
                        size:30px;
                        family: Arial,'Microsoft YaHei';
                    }
                    color: #999;
                }
            }
        }
    }
</style>