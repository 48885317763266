<template>
  <el-form :model="form" ref="form" :rules="rules" label-width="80px">
    <el-form-item prop="name" label="賬戶">
      <el-input v-model="form.name" placeholder="請輸入賬戶" />
    </el-form-item>
    <el-form-item prop="pwd" label="密碼">
      <el-input v-model="form.pwd" placeholder="請輸入新密碼" type="password" />
    </el-form-item>
    <el-form-item prop="phone" label="電話">
      <el-input type="text" v-model="form.phone" placeholder="請輸入電話號碼" />
    </el-form-item>
    <el-button type="primary" @click="submitHandler">提交</el-button>
    <el-button type="warning" @click="resetHandler">重置</el-button>
  </el-form>
</template>

<script>
import { getUserInfo, updateUser } from "@/api/getUserData";
export default {
  data() {
    return {
      rules: {
        name: [{ required: true, message: "请填写账户名", trigger: "blur" }],
        pwd: [{ required: true, message: "请填写密碼", trigger: "blur" }],
        phone: [{ required: true, message: "请填写電話號碼", trigger: "blur" }],
      },
      form: {},
    };
  },
  computed: {
    getToken() {
      return this.$store.state.token;
    },
  },

  methods: {
    resetHandler(e) {
      this.form.name = "";
      this.form.pwd = "";
      this.form.phone = "";
      console.log("reset", e);
    },
    goTo(path) {
      this.$router.push(path);
    },
    async getInfo() {
      try {
        const result = await getUserInfo(this.getToken);
        if (result.data.code === 0) {
          this.form = result.data.data;
          this.form.pwd = null;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async signOut() {
      //清除token
      await this.$store.dispatch("clearToken");
      localStorage.removeItem("token");
      //刷新页面
      location.reload();
    },
    submitHandler(e, form) {
      if (this.form.pwd == null || this.form.pwd == "") {
        this.form.pwd = null;
      }
        
      updateUser(this.$store.state.token, this.form.name, this.form.pwd, this.form.phone, null, this.form.id).then((res) => {
        if (res.data.code === 0) {
          const toast = this.$createToast({
            txt: "好心情已經記錄下來啦<br/>請查收",
            type: "correct",
            time: 1500,
          });
          toast.show();
          this.signOut();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
  created() {
    if (this.getToken) {
      this.getInfo();
    }
  },
};
</script>
