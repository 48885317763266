<template>
  <div>
    <detail-header :title="title"></detail-header>
    <div class="app-container" style="padding-bottom:120px; padding-top:80px;">
      <el-card>
        <el-form :model="model" label-width="80px">
          <el-form-item label="文章标题">
            <el-input v-model="model.title" placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label="幸福度">
            <el-rate
    v-model="model.happiness"
    :colors="colors" style="padding-top: 10px;" :max="10" :low-threshold="3" :high-threshold="7">
  </el-rate>
          </el-form-item>
          <el-form-item label="学习记录">
            <div class="editor-container">
              <mavon-editor v-model="model.learningRec"  :ishljs="true" :boxShadow="false" @change="editorLearningRec" :editable="true" style="z-index: 0;"></mavon-editor>
            </div>
          </el-form-item>
          <el-form-item label="生活记录">
            <div class="editor-container"> 
              <mavon-editor v-model="model.lifeRec"  :ishljs="true" :boxShadow="false" @change="editorLifeRec" :editable="true" style="z-index: 0;"></mavon-editor>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitHandler">提交</el-button>
            <el-button type="warning" @click="resetHandler">重置</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
    <common-footer></common-footer>
  </div>
</template>

<script>
import DetailHeader from "@/components/Header";
import CommonFooter from "@/components/CommonFooter.vue";
import { getUserInfo } from "@/api/getUserData.js";
import { record } from "@/api/getDiaryData.js";


export default {
  
  //注册组件
  components: {
    CommonFooter,
    DetailHeader,
  },

  data() {
    return {
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
      title: "PC端提交",
      model: {
        title: "",
        happiness: 3,
        learningRec: "",
        learningRecMd: "",
        lifeRec: "",
        lifeRecMd: "",
      },
      info: {},
    };
  },

  computed: {
    getToken() {
      return this.$store.state.token;
    },
  },
  methods: {
    //markdown编辑器数据变化时触发
    editorLearningRec(value, render) {
      this.model.learningRec = value;
      this.model.learningRecMd = render;
    },
    editorLifeRec(value, render) {
      this.model.lifeRec = value;
      this.model.lifeRecMd = render;
    },

    //获取用户信息
    async getInfo() {
      try {
        const result = await getUserInfo(this.getToken);
        if (result.data.code === 0) {
          this.info = result.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    resetHandler(e) {
      this.model.title = "";
      this.model.happiness = "";
      this.model.learningRec = "";
      this.model.learningRecMd = "";
      this.model.lifeRec = "";
      this.model.lifeRecMd = "";
      console.log("reset", e);
    },
    submitHandler(e, model) {
      e.preventDefault();
      //调用注册接口
      record(
        this.$store.state.token,
        this.model.title,
        this.model.happiness,
        this.model.learningRec,
        this.model.learningRecMd,
        this.model.lifeRec,
        this.model.learningRecMd,
        this.info.id,
        this.info.head_img
      ).then((res) => {
        if (res.data.code === 0) {
          const toast = this.$createToast({
            txt: "好心情已經記錄下來啦<br/>請查收",
            type: "correct",
            time: 1500,
          });
          toast.show();
        } else {
          const toast = this.$createToast({
            txt: "沒有成功提交",
            type: "error",
            time: 1500,
          });
          toast.show();
        }
      });
    },
    goTo(path) {
      this.$router.push(path);
    },
  },
  mounted() {
    //渲染完成后拿数据
    if (this.getToken) {
      this.getInfo();
    }
  },
};
</script>

<style lang="scss" scoped>
.editor-container {
  margin-bottom: 30px;

  .tui-editor-defaultUI .te-mode-switch-section {
    height: 40px !important;
  }
}
.cover {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>

 <style lang="scss" scoped>
.app-container {
  padding: 50px 5% 0;
}
.main {
  padding: 50px 5% 0;
  text-align: center;
}
.header {
  display: flex; //flex左右布局
  background-color: #07111b;
  padding: 10px 20px;
  color: #fff;
}
.login {
  display: block;
  background-color: #3bb149;
  border: none;
  outline: none;
  width: 80%;
  height: 40px;
  margin: 20px auto 0;
  padding: 20px 5% 0;
  color: #fff;
  border-radius: 20px;
}
</style>
<style lang="stylus" rel="stylesheet/stylus">
.slide-container-v {
  height: 265px;
  margin-bottom: 15px;
  transform: translateZ(0px);
  border-radius: 2px;
  overflow: hidden;
  box-shadow: 0 2px 9px #ddd;

  .cube-slide-dots {
    .my-dot {
      height: auto;
      font-size: 12px;
      background: none;

      &.active {
        color: #fc9153;
      }
    }
  }
}

.cube-slide-item > a > img {
  width: 100%;
  height: auto;
}
</style>