<template>
  <div>
    <header id="header" class="header bg-white" style="height: 60px">
      <div class="navbar-container" style="height: 30px">
        <a href="/" class="navbar-logo">
          <img src="/logo.png" alt="YoyoLee" style="height: 50px" />
        </a>
        <!-- <div class="navbar-menu">
          <a href="/PC/">電腦端</a>
        </div> -->
        <div class="navbar-menu">
          <a href="http://web.yoyolee2021.site/PC/" style="font-size:14px;">電腦端</a>
          <a href="http://web.yoyolee2021.site/blog/" style="font-size:14px;">部落格</a>
        </div>
        <div class="navbar-mobile-menu" onclick="">
          <span class="icon-menu cross"><span class="middle"></span></span>
          <ul>
            <!-- <li><a href="/PC/dashboard">電腦端</a></li> -->
            <li><a href="http://web.yoyolee2021.site/PC/" style="font-size:14px;">電腦端</a></li>
            <li><a href="http://web.yoyolee2021.site/blog/" style="font-size:14px;">部落格</a></li>
          </ul>
        </div>
      </div>
    </header>

    <router-view style="padding-top: 50px" />

    <footer id="footer" class="footer bg-white" style="padding-bottom: 80px">
      <div class="footer-social">
        <div class="footer-container clearfix">
          <div class="social-list">
            <a class="social rss" target="blank" href="https://tycoding.cn/"
              >BLOG</a
            >
            <a
              class="social zhihu"
              target="blank"
              href="https://www.zhihu.com/people/tomo-83-82/activities"
              >ZHIHU</a
            >
            <a
              class="social github"
              target="blank"
              href="https://github.com/TyCoding"
              >GITHUB</a
            >
          </div>
        </div>
      </div>
      <div class="footer-meta" style="padding-bottom: 80px">
        <div class="footer-container">
          <div class="meta-item meta-copyright">
            <div class="meta-copyright-info">
              <a href="/">
                <img src="/logo.png" alt="YoyoLee" style="height: 60px" />
              </a>
              <div class="info-text">
                <p id="chakhsu">
                  I work with J<span style="color: rgb(255, 120, 71)"
                    >&amp;</span
                  ><span style="color: rgb(255, 94, 99)">4</span
                  ><span style="color: rgb(255, 94, 99)">U</span
                  ><span style="color: rgb(191, 60, 175)">+</span
                  ><span style="color: rgb(226, 183, 47)">`</span>
                </p>
                <p>
                  Theme is
                  <a href="https://github.com/chakhsu/pinghsu" target="_blank"
                    >Pinghsu</a
                  >
                  by
                  <a href="https://www.linpx.com/" target="_blank">Chakhsu</a>
                </p>
                <p>
                  Powered by
                  <a
                    href="http://www.typecho.org"
                    target="_blank"
                    rel="nofollow"
                    >YoyoLee</a
                  >
                </p>
                <p>© 2021 <a href="https://tycoding.cn/">YoyoLee</a></p>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="icp">
          <p>ICP证:<a href="http://beian.miit.gov.cn">苏ICP备2021042385号-1</a></p>
        </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Layout",
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let r = document.getElementById("chakhsu");
      if (r.loaded) {
        return false;
      }

      function t() {
        return b[Math.floor(Math.random() * b.length)];
      }

      function e() {
        return String.fromCharCode(94 * Math.random() + 33);
      }

      function n(r) {
        for (var n = document.createDocumentFragment(), i = 0; r > i; i++) {
          var l = document.createElement("span");
          (l.textContent = e()), (l.style.color = t()), n.appendChild(l);
        }
        return n;
      }

      function i() {
        var t = o[c.skillI];
        c.step
          ? c.step--
          : ((c.step = g),
            c.prefixP < l.length
              ? (c.prefixP >= 0 && (c.text += l[c.prefixP]), c.prefixP++)
              : "forward" === c.direction
              ? c.skillP < t.length
                ? ((c.text += t[c.skillP]), c.skillP++)
                : c.delay
                ? c.delay--
                : ((c.direction = "backward"), (c.delay = a))
              : c.skillP > 0
              ? ((c.text = c.text.slice(0, -1)), c.skillP--)
              : ((c.skillI = (c.skillI + 1) % o.length),
                (c.direction = "forward"))),
          (r.textContent = c.text),
          r.appendChild(
            n(
              c.prefixP < l.length
                ? Math.min(s, s + c.prefixP)
                : Math.min(s, t.length - c.skillP)
            )
          ),
          setTimeout(i, d);
      }

      var l = "I work with ",
        o = [
          "Front-End",
          "Java",
          "Vue & CSS",
          "Spring",
          "Spring Cloud",
          "passion & love",
        ].map(function (r) {
          return r + ".";
        }),
        a = 2,
        g = 1,
        s = 5,
        d = 75,
        b = [
          "rgb(110,64,170)",
          "rgb(150,61,179)",
          "rgb(191,60,175)",
          "rgb(228,65,157)",
          "rgb(254,75,131)",
          "rgb(255,94,99)",
          "rgb(255,120,71)",
          "rgb(251,150,51)",
          "rgb(226,183,47)",
          "rgb(198,214,60)",
          "rgb(175,240,91)",
          "rgb(127,246,88)",
          "rgb(82,246,103)",
          "rgb(48,239,130)",
          "rgb(29,223,163)",
          "rgb(26,199,194)",
          "rgb(35,171,216)",
          "rgb(54,140,225)",
          "rgb(76,110,219)",
          "rgb(96,84,200)",
        ],
        c = {
          text: "",
          prefixP: -s,
          skillI: 0,
          skillP: 0,
          direction: "forward",
          delay: a,
          step: g,
        };
      i();
      r.loaded = true;
    },
  },
};
</script>

<style scoped>
@import "~@/styles/style.min.css";
  .icp{
    width:100%;
    text-align :center;
    margin-top:100px;
  }
</style>