import axios from '../request'

export const updateUser = (token, name, pwd, phone, head_img, id) => axios.post("/api/v1/pri/user/update_user", {
    "phone": phone,
    "pwd": pwd,
    "name": name,
    "id": id,
    "head_img": head_img
}, {
    headers: { "token": token }
});

//注册接口
export const registerApi = (phone, pwd, name) => axios.post("/api/v1/pri/user/register", {
    "phone": phone,
    "pwd": pwd,
    "name": name
});

//登录接口
export const loginApi = (phone, pwd) => axios.post("/api/v1/pri/user/login", {
    phone,
    pwd
});

//用户信息接口
export const getUserInfo = (token) => axios.get("/api/v1/pri/user/find_by_token", {
    params: {
        "token": token
    }
});
