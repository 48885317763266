<template>
  <div>
    <detail-header :title="title"></detail-header>
    <template>
      <div slot="content" style="padding-bottom: 160px; padding-top: 60px">
        <el-table :data="list" stripe style="width: 100%" class="audio-js">
          <!--  -->
          <el-table-column label="" width="60" align="center">
            <template #default="{ row }">
              <div @click="handlerPlay(row.id)">
                <span>{{ row.id }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="歌曲標題" >
            <template #default="{ row }">
              <div @click="handlerPlay(row.id)">
                <span>{{ row.name }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="" width="60" align="center">
            <template #default="{ row }">
              <span class="music-btn"
                ><i
                  :class="
                    player[row.id] && player[row.id].play
                      ? 'el-icon-video-pause'
                      : 'el-icon-video-play'
                  "
                  @click="handlerPlay(row.id)"
                ></i
              ></span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </template>
    <div class="bubble">
      <span>當前歌曲：{{ list[index].name }}</span>

      <el-slider
        v-model="sliderVal"
        :format-tooltip="formatTooltip"
        :min="sliderMin"
        :max="sliderMax"
        @change="spliderSelect"
      />
      <div>
        <div class="operators">
          <div style="width:80px;font-size:25px;">{{currentTime}}</div>
          <div class="icon i-left;">
            <i class="iconfont icon-xiayishou1" @click="musicPlay('pre')"></i>
          </div>
          <div class="icon i-center">
            <i
              class="iconfont"
              :class="play ? 'icon-zanting' : 'icon-bofang'"
              @click="musicPlay('play')"
            ></i>
          </div>
          <div class="icon i-right">
            <i
              class="icon-next iconfont icon-xiayishou"
              @click="musicPlay('next')"
            ></i>
          </div>
            <div style="width:80px; font-size:25px;">{{duration}}</div>
        </div>
      </div>
    </div>
    <audio
      controls
      ref="singeBox"
      style="
        opacity: 0;
        max-width: 3px;
        _width: expression(this.width > 3 ? “3px”: this.width);
        max-height: 1px;
        _width: expression(this.width > 1 ? “1px”: this.width);
      "
    ></audio>
    <!-- </div> -->
    <common-footer></common-footer>
  </div>
</template>

<script>
import CommonFooter from "@/components/CommonFooter";
import DetailHeader from "@/components/Header";
import { getAudioList } from "@/api/getData";
// 新增代码：引入特定组件及样式

export default {
  components: {
    CommonFooter,
    DetailHeader,
  },
  data() {
    return {
      player: {},
      index: 0, // 当前播放的音乐素质索引
      play: false, // 播放状态，true为正在播放
      sliderVal: 0, // 这个对接当前时长。
      sliderMin: 0,
      sliderMax: 0, // 这个对接总时长。
      title: "MEDITATION PLAYER",

      duration: undefined, // 音乐总时长
      currentTime: undefined, // 当前播放时长
      box: undefined,
      musicPath: "http://119.3.129.176/music/", // mp3链接
      coverPath: "http://119.3.129.176/images/", // 封面
      musicTitle: "", // 当前播放标题
      musicImg: "", //  当前播放封面
      list: [
        //数据格式
        {
          id: 1,
          name: "01Day 1-Mindful Basics _ Arriving in Presence",
          url: "01",
          cover: "avatar1",
          singer: "unknown",
          time: 600,
        },
      ],
    };
  },
  computed: {
    getToken() {
      return this.$store.state.token;
    },
  },
  methods: {
    //获取用户信息
    async getAudioList() {
      try {
        const result = await getAudioList(this.getToken);
        if (result.data.code === 0) {
          this.list = result.data.data;
          console.log(this.list);
        }
      } catch (error) {
        console.log(error);
      }
    },
    handlerPlay(id) {
      console.log(this.player);
      if (!this.player[id]) {
        // 没值触发新的播放
        const i = this.list.findIndex((x) => x.id === id);
        this.index = i;
        this.play = false;
      }
      this.musicPlay("play");
    },
    musicPlay(flag) {
      switch (flag) {
        case "pre":
          if (this.list[this.index - 1]) {
            this.box.src =
              this.musicPath + this.list[this.index - 1].url + ".mp3";
      this.sliderMax = this.list[this.index - 1].time;
              
            this.index -= 1;
          } else {
            this.box.src =
              this.musicPath + this.list[this.list.length - 1].url + ".mp3";
                    this.sliderMax = this.list[this.list.length - 1].time;

            this.index = this.list.length - 1;
          }
          break;
        case "play":
          this.play = !this.play;
          // 对接控件 同步 列表里的控件
          if (this.player[this.list[this.index].id])
            this.player[this.list[this.index].id].play = this.play;
      this.sliderMax = this.list[this.index].time;

          // 新的歌曲播放
          if (this.play && !this.player[this.list[this.index].id])
            this.box.src = this.musicPath + this.list[this.index].url + ".mp3";
      this.sliderMax = this.list[this.index].time;

          break;
        case "next":
          if (this.list[this.index + 1]) {
            this.box.src =
              this.musicPath + this.list[this.index + 1].url + ".mp3";
      this.sliderMax = this.list[this.index + 1].time;

            this.index += 1;
          } else {
            this.box.src = this.musicPath + this.list[0].url + ".mp3";
      this.sliderMax = this.list[0].time;

            this.index = 0;
          }
          break;
      }
      if (this.play && !this.player[this.list[this.index].id]) {
        this.player = {};
        this.player[this.list[this.index].id] = {};
        this.player[this.list[this.index].id].play = true;
      } else {
        this.play ? this.box.play() : this.box.pause();
      }
    },
    init() {
      let _that = this;
      this.box = this.$refs.singeBox;
      this.box.src = this.list[0].url + ".mp3";
      this.sliderMax = this.list[0].time;
      // 绑定三个触发方法
      // 当时长有变化时触发，由"NaN"变为实际时长也算
      this.box.ondurationchange = function () {
        // console.log("时长发生了变化");
        _that.play ? _that.box.play() : _that.box.pause();
        _that.updateTime();
      };

      // 当前数据可用是触发
      this.box.oncanplay = function () {
        // console.log("已经可以播放了");
        _that.play ? _that.box.play() : _that.box.pause();
      };
      // 播放位置发送改变时触发。
      this.box.ontimeupdate = function () {
        // console.log("播放位置发送了变动");
        _that.updateTime();
      };
      // 音频播放完毕
      this.box.onended = function () {
        _that.musicPlay("next");
        // console.log("播放完毕，谢谢收听");
      };
      // 音频播放完毕
      this.box.onerror = function () {
        // console.log("加载出错！");
      };
    },
    updateTime() {
      const total = this.formatTime(this.box.duration);
      const current = this.formatTime(this.box.currentTime);
      this.duration = `${total.min}:${total.sec}`;
      this.currentTime = `${current.min}:${current.sec}`;
      // 值为xx.xxxxx 需要取整
      this.sliderVal = Math.floor(this.box.currentTime);
      this.musicTitle =
        this.list[this.index].name + " - " + this.list[this.index].singer;
      this.musicImg = this.coverPath + this.list[this.index].cover + ".jpg";
    },
    formatTime(time) {
      // 格式化毫秒，返回String型分秒对象
      // 有可能没获取到，为NaN
      if (!time) return { min: "00", sec: "00" };
      return {
        min: Math.floor(time / 60)
          .toString()
          .padStart(2, "0"),
        sec: Math.floor(time % 60)
          .toString()
          .padStart(2, "0"),
      };
    },
    formatTooltip(val) {
      // 格式化毫秒数，由于组件提示为纯数字，所以这里需要将数字转化为我们所需要的的格式，string类型的。'03:45',
      const time = this.formatTime(val);
      return `${time.min}:${time.sec}`;
    },
    spliderSelect() {
      // 滑块松动后触发。更新当前时长，
      // 时长发生变动会init里的方法进行更新数据
      this.box.currentTime = this.sliderVal;
      // this.box.currentTime = 15
    },
  },
  mounted() {
    this.getAudioList();
    this.init();
  },
};
</script>

<style lang="stylus" scoped>
.operators {
  display: flex;
  align-items: center;

  .icon {
    flex: 1;
    text-align: center;

    // color: $color-theme
    i {
      font-size: 50px;
      color: black;
    }

    .i-left {
      text-align: right;
    }

    .i-center {
      padding: 0 20px;
      text-align: center;

      i {
        font-size: 40px;
      }
    }

    .i-right {
      text-align: left;
    }
  }
}
</style>
<style scoped>
i {
      font-size: 35px;
      color: black;
    }
.audio-js {
  width: 100%;
  height: 100%;
  display: block;
}
.cool {
  padding: 40px 0 160px;
  position: relative;
}
.bubble {
  padding: 7px 10px;
  padding-bottom: 50px;
  color: #333;
  background: rgb(255, 255, 255);
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1);
  /* position: relative; */
  line-height: 1rem;
  width: 100%;
  /* max-width: 420px;
  min-width: 80px; */
  margin: 0 20px 0 5px;

  position: fixed;
  bottom: 40px;
  /* z-index: 999; */
  /* width: 100%; */
  /* border-top: 1px solid rgba($color: #000000, $alpha: 0.1); */
}
.center {
  text-align: center;
}
span {
  font-size: 30px;
  line-height: 33px;
}
</style>