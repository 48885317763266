<template>
  <div class="event-tools" :class="{ 'show-event-tools': isShow }">
    <ul class="tools-sidebar">
      <li>
        <button class="tools-btn" @click="openTheme" >切換主題</button>
      </li>
      <li>
        <button class="tools-btn" @click="downloadData(getData)" >
          保存數據
        </button>
      </li>
      <li>
        <button class="tools-btn" @click="openTable" >編輯數據</button>
      </li>
      <li>
        <button class="tools-btn" @click="showDialog" >清空數據</button>
      </li>
    </ul>
  </div>
</template>

<script>
import { getToDoList, saveToDo } from "@/api/getToDoData";
export default {
  data() {
    return {};
  },
  props: ["isShow"],
  computed: {
    getData() {
      const self = this;
      return JSON.stringify(self.$store.state);
    },
  },
  methods: {
    showDialog() {
      this.$emit("cleardialog");
    },
    openTable() {
      this.$emit("opentable");
    },
    openTheme() {
      this.$emit("opentheme");
    },
    downloadData(content) {
      content = JSON.stringify(JSON.parse(content)["event"]);
      saveToDo(this.$store.state.token, content).then((res) => {
        if (res.data.code === 0) {
          const toast = this.$createToast({
            txt: "已經保存下來啦<br/>請查收",
            type: "correct",
            time: 1500,
          });
          toast.show();
        } else {
          const toast = this.$createToast({
            txt: "沒有成功提交",
            type: "error",
            time: 1500,
          });
          toast.show();
        }
      });
      console.log(content);
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" >
.event-tools {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 250px;
  padding: 140px 20px 30px;
  box-sizing: border-box;
  transform: translateX(-250px);
  transition: transform 0.3s;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  font-size: 30px;
  z-index: 9;
  &.show-event-tools {
    transform: translateX(0);
  }
  .tools-sidebar {
    & > li {
      line-height: 60px;
    }
  }
  .tools-btn {
    margin-top:10px;
    margin-bottom:10px;
    font-size:40px;
    width: 190px;
    height: 60px;
    line-height: 30px;
    color: #fff;
    transition: background 0.3s ease-in;
  }
  pre {
    white-space: pre-wrap;
    font: {
      size: 30px;
      family: arial, "microsoft yahei";
    }
    line-height: 40px;
  }
}
</style>
