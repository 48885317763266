<template>
    <div class="event-content">
        <div class="event-tab" style="font-size:20px;" @click="changeCollapse(0,$event)">未完成
            <span  :class="{'close-span': !collapse[0].show}"></span>
        </div>
        <div class="event-box" :style="{'height':'auto','display':'block'}">
            <ul>
                <li class="event-list" v-for="value in getToDo" :key="value.id" style="font-size:20px;">
                    <input type="checkbox" style="margin-top:10px; width:20px;height:20px;" :key="value.id" @click="moveToDone(value.id)">
                    <div>{{value.content}}</div>
                    <button class="cancel-btn" @click="moveCancel(value.id)">取消</button>
                </li>
            </ul>
        </div>

        <div class="event-tab" style="font-size:20px;" @click="changeCollapse(1,$event)">已完成
            <span :class="{'close-span': !collapse[1].show}"></span>
        </div>
        <div class="event-box">
            <ul>
                <li class="event-list" v-for="value in getDone" :key="value.id" style="font-size:20px;">
                    <input type="checkbox" style="margin-top:10px;width :20px; height: 20px;" :key="value.id" checked @click="moveToDo(value.id)">
                    <div>{{value.content}}</div>
                    <span class="event-time" style="font-size:16px; padding-top:10px;">{{value.time}}</span>
                </li>
            </ul>
        </div>

        <div class="event-tab" style="font-size:20px;" @click="changeCollapse(2,$event)">已取消
            <span :class="{'close-span': !collapse[2].show}"></span>
        </div>
        <div class="event-box" :class="{'event-box-hide': false}">
            <ul>
                <li class="event-list" v-for="value in getCancel" :key="value.id" style="font-size:20px;">
                    <div class="event-delete">{{value.content}}</div>
                    <button class="cancel-btn" @click="moveToDo(value.id)">恢復</button>
                </li>
            </ul>
        </div>

    </div>
</template>

<script>
    export default {
        data(){
            return {
                collapse:[
                    {
                        show: true,
                    },
                    {
                        show: true,
                    },
                    {
                        show: true,
                    }
                ],
            }
        },
        computed: {
            getToDo(){
                return this.$store.getters.getToDo;
            },
            getDone(){
                return this.$store.getters.getDone;
            },
            getCancel(){
                return this.$store.getters.getCancel;
            },
        },
        methods: {
            moveToDone(id){ //移至已完成
                this.$store.dispatch('eventdone', id);
            },
            moveToDo(id){ //移至未完成
                this.$store.dispatch('eventtodo', id);
            },
            moveCancel(id){ //移至已取消
                this.$store.dispatch('eventcancel', id);
            },
            changeCollapse(num, event){
                const show = this.collapse[num].show;
                if (show) {
                    this.closeCollapse(event);
                } else {
                    this.openCollapse(event);
                }
                this.collapse[num].show = !show;
            },
            closeCollapse(event){ // 关闭折叠面板
                let ulElement = event.currentTarget.nextElementSibling,
                    children = ulElement.getElementsByTagName('ul')[0];
                ulElement.style.height = children.offsetHeight + 'px';
                setTimeout(function(){
                    ulElement.style.height = '0px';
                    setTimeout(function () {
                        ulElement.style.display = 'none';
                    }, 300)
                },10)
            },
            openCollapse(event){ // 打开折叠面板
                let ulElement = event.currentTarget.nextElementSibling,
                    children = ulElement.getElementsByTagName('ul')[0];
                ulElement.style.display = 'block';
                ulElement.style.height = children.offsetHeight + 'px';
                setTimeout(function () {
                    ulElement.style.height = 'auto';
                }, 300)
            }
        }
    }
</script>

<style lang="scss" rel="stylesheet/scss" >
    .event-content {
        .event-tab {
            position: relative;
            height: 70px;
            line-height: 65px;
            padding-left: 40px;
            border-bottom: 1px solid #fff;
            box-sizing: border-box;
            color: #fff;
            cursor: pointer;
            span {
                position: absolute;
                right: 40px;
                top: 20px;
                width: 20px;
                height: 20px;
                content: '';
                border: {
                    top: 2px solid #fff;
                    right: 2px solid #fff;
                }
                transform: rotate(135deg);
                transition: transform .3s;
                &.close-span {
                    transform: rotate(45deg);
                }
            }
        }
        .event-box {
            list-style: none;
            overflow: hidden;
            border: {
                left: 1px solid #eee;
                right: 1px solid #eee;
            }
            transition: height .3s;
            .event-list {
                position: relative;
                min-height: 55px;
                line-height: 60px;
                padding: 20px 160px 20px 100px;
                box-sizing: border-box;
                border-bottom: 1px solid #eee;
                color: #373e40;
                input[type=checkbox] {
                    position: absolute;
                    left: 15px;
                    top: 12px;
                    width: 20px;
                    height: 60px;
                }
                .cancel-btn {
                    margin-top:10px;
                    position: absolute;
                    right: 10px;
                    top: 7px;
                    width: 150px;
                    height: 60px;
                    line-height: 55px;
                    padding: 0;
                    background: #fff;
                    border: 1px solid #c0ccda;
                    color: #666;
                    font-size: 35px;
                }
                .event-time {
                    position: absolute;
                    right: 10px;
                    top: 12px;
                    line-height: 44px;
                    font-size: 20px;
                    color: #aaa;
                }
                .event-delete {
                    text-decoration: line-through;
                    color: #999;
                }
            }
        }
    }
</style>