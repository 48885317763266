<template>
  <div>
    <div class="main">
      <cube-form :model="model" @submit="submitHandler">
        <cube-form-group>
          <!--手机号-->
          <cube-form-item :field="fields[0]"></cube-form-item>
          <!--密码-->
          <cube-form-item :field="fields[1]"></cube-form-item>
        </cube-form-group>

        <cube-form-group>
          <cube-button type="submit">登錄</cube-button>
        </cube-form-group>
      </cube-form>
      <router-link to="/register" class="reg">註冊</router-link>
    </div>
    <common-footer></common-footer>
  </div>
</template>

<script>
//登录接口
import CommonFooter from "@/components/CommonFooter";

import { loginApi } from "@/api/getUserData.js";
export default {
  components: {
    CommonFooter,
  },
  data() {
    return {
      model: {
        phoneValue: "",
        pwdValue: "",
      },
      fields: [
        {
          type: "input",
          modelKey: "phoneValue",
          label: "手機號",
          props: {
            placeholder: "請輸入手機號",
          },
          rules: {
            required: true,
          },
          messages: {
            required: "手機號不能為空",
          },
        },
        {
          type: "input",
          modelKey: "pwdValue",
          label: "密碼",
          props: {
            placeholder: "請輸入密碼",
            type: "password",
            eye: {
              open: false,
            },
          },
          rules: {
            required: true,
          },
          messages: {
            required: "密碼不能為空",
          },
        },
      ],
    };
  },
  methods: {
    submitHandler(e, model) {
      e.preventDefault();
      //调用注册接口
      loginApi(model.phoneValue, model.pwdValue).then((res) => {
        if (res.data.code === 0) {
          //登录成功，跳转到个人中心
          localStorage.setItem("token", res.data.data);

          this.$store.dispatch("setToken", res.data.data);

          //跳转页面, 根据业务需要
          this.$router.push({ path: "/personal" });
          // this.$router.back(-1);
        } else {
          const toast = this.$createToast({
            txt: "登錄失敗",
            type: "error",
            time: 1500,
          });
          toast.show();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  padding: 50px 5% 0;
  text-align: center;
}
// 登录
.cube-btn {
  margin-top: 20px;
}
//注册
.reg {
  display: inline-block;
  margin-top: 30px;
  font-size: 18px;
}
</style>