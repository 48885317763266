<template>
  <div>
    <detail-header :title="title"></detail-header>
    <div
      id="app"
      :class="[getTheme]"
      style="padding-top: 37px; padding-bottom: 90px"
    >
      <n-header @tools="changePages" style="font-size: 20px"></n-header>
      <section class="container" :class="{ hide: table }">
        <n-add></n-add>
        <n-list></n-list>
        <n-sidebar
          :is-show="tools"
          @cleardialog="clearData"
          @uploadData="uploadData"
          @opentable="
            table = true;
            tools = false;
          "
          @opentheme="
            theme = true;
            tools = false;
          "
        ></n-sidebar>
      </section>
      <transition name="dialog">
        <!-- <n-dialog :is-show="dialog" :msg="tips" @cancel="dialog = false" @sure="sureDialog"></n-dialog> -->
        <n-dialog
          v-show="dialog"
          :msg="tips"
          @cancel="dialog = false"
          @sure="sureDialog"
        ></n-dialog>
      </transition>

      <n-table
        @deldialog="delData"
        :is-show="table"
        @close="table = false"
      ></n-table>
      <n-theme :is-show="theme" @close="theme = false"></n-theme>
    </div>
    <common-footer></common-footer>
  </div>
</template>

<script>
import nHeader from "./components/header.vue";
import nAdd from "./components/event_add.vue";
import nTable from "./components/event_table.vue";
import nList from "./components/event_list.vue";
import nSidebar from "./components/sidebar.vue";
import nDialog from "./components/dialog.vue";
import nTheme from "./components/theme.vue";
import DetailHeader from "@/components/Header.vue";
import CommonFooter from "@/components/CommonFooter";
import { getToDoList } from "@/api/getToDoData";

export default {
  data() {
    return {
      toDo: "",
      events: "",

      title: "ToDoList",
      tools: false,
      dialog: false,
      table: false,
      theme: false,
      dialog_type: "",
      tips: "",
      del_info: {
        index: 0,
        id: 0,
      },
    };
  },
  components: {
    CommonFooter,
    DetailHeader,
    nHeader,
    nAdd,
    nSidebar,
    nDialog,
    nTable,
    nList,
    nTheme,
  },
  computed: {
    getTheme() {
      // 获取主题色
      return this.$store.getters.getTheme;
    },
    getToken() {
      return this.$store.state.token;
    },
  },
  methods: {
    async getToDo() {
      try {
        const result = await getToDoList(this.getToken);
        if (result.data.code === 0) {
          this.toDo = result.data.data.to_do_list;

          this.$store.dispatch("uploadevent", this.toDo);
          console.log(this.toDo);
        }
      } catch (error) {
        console.log(error);
      }
    },
    clearData() {
      this.tools = false;
      this.dialog = true;
      this.dialog_type = "clear";
      this.tips = "清空後還是可以恢復上次保存記錄，確認清空？";
    },
    delData(index, id) {
      this.dialog = true;
      this.dialog_type = "del";
      this.tips = "刪除後若之前無保存記錄則無法恢復，確認刪除嗎？";
      this.del_info = {
        index: index,
        id: id,
      };
    },
    uploadData() {
      this.tools = false;
      this.dialog = true;
      this.dialog_type = "upload";
      this.tips = "upload";
    },
    sureDialog() {
      const self = this;
      switch (self.dialog_type) {
        case "clear":
          self.$store.dispatch("clearevent");
          self.$toast("清空數據成功");
          break;
        case "del":
          self.$store.dispatch("delevent", self.del_info);
          self.$toast("刪除成功");
          break;
      }
      this.dialog = false;
    },
    changePages() {
      if (this.table) {
        this.table = !this.table;
      } else if (this.theme) {
        this.theme = !this.theme;
      } else {
        this.tools = !this.tools;
      }
    },
  },
  mounted() {
    this.getToDo();
  },
};
</script>
<style lang="scss" rel="stylesheet/scss" src="@/static/theme.scss"></style>