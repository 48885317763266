<template>
  <div>
    <detail-header :title="title"></detail-header>
    <div>
      <div>
        <div class="main" style="padding-bottom:120px; padding-top:80px;">
          <div
            class="avatar"
            :style="{
              backgroundImage: 'url(' + (this.model.userImg || '') + ')',
            }"
          >
            <br /><br />
            <p class="bold">{{ this.model.userId }}</p>
          </div>

          <div class="bubble">
            <div class="content">
              <span class="bold">標題:</span> &nbsp;{{ this.model.title }}
            </div>

            <div class="content">
              <span class="bold">幸福度:</span> &nbsp;
              <el-rate
                v-model="model.happiness"
                :colors="colors"
                :max="10"
                :low-threshold="3"
                :high-threshold="7"
                :disabled="true"
              >
              </el-rate>
            </div>
            <div class="content">
              <span class="bold">創建時間:</span> &nbsp;{{
                this.model.createTime
              }}
            </div>
            <div class="content">
              <span class="bold">學習記錄:</span> &nbsp;
              <mavon-editor
                v-model="model.learningRec"
                :ishljs="true"
                :boxShadow="false"
                :editable="false"
                :subfield="false"
                :toolbars="toolbars"
                :defaultOpen="preview"
                style="min-height: 150px; min-width: 150px; z-index: 0"
              ></mavon-editor>
            </div>
            <div class="content">
              <span class="bold">生活記錄:</span> &nbsp;
              <mavon-editor
                v-model="model.lifeRec"
                :ishljs="true"
                :boxShadow="false"
                :editable="false"
                :subfield="false"
                :toolbars="toolbars"
                :defaultOpen="preview"
                style="min-height: 150px; min-width: 150px; z-index: 0"
              ></mavon-editor>
            </div>
          </div>
          <div style="text-align: right">
            <el-button
              type="primary"
              @click="goTo('/DiaryUpdate')"
              icon="el-icon-edit" style="font-size:15px; line-height:20px;"
              >手機端</el-button
            >
            <el-button
              type="primary"
              @click="goTo('/DiaryUpdatePC')"
              icon="el-icon-edit" style="font-size:15px; line-height:20px;"
              >電腦端</el-button 
            >
          </div>
        </div>
      </div>
    </div>
    <common-footer></common-footer>
  </div>
</template>

<script>
import { getDiaryDetail } from "@/api/getDiaryData.js";
import CommonFooter from "@/components/CommonFooter.vue";
import DetailHeader from "@/components/Header.vue";
export default {
  //注册组件
  components: {
    DetailHeader,
    CommonFooter,
  },
  props: {
    preview: {
      type: String,
      default: "preview",
    },
  },
  data() {
    return {
      colors: ["#99A9BF", "#F7BA2A", "#FF9900"],
      toolbars: {
        bold: false, // 粗体
        italic: false, // 斜体
        header: false, // 标题
        underline: false, // 下划线
        strikethrough: false, // 中划线
        mark: false, // 标记
        superscript: false, // 上角标
        subscript: false, // 下角标
        quote: false, // 引用
        ol: false, // 有序列表
        ul: false, // 无序列表
        link: false, // 链接
        imagelink: false, // 图片链接
        code: false, // code
        table: false, // 表格
        fullscreen: false, // 全屏编辑
        readmodel: false, // 沉浸式阅读
        htmlcode: false, // 展示html源码
        help: false, // 帮助
        /* 1.3.5 */
        undo: false, // 上一步
        redo: false, // 下一步
        trash: false, // 清空
        save: false, // 保存（触发events中的save事件）
        /* 1.4.2 */
        navigation: false, // 导航目录
        /* 2.1.8 */
        alignleft: false, // 左对齐
        aligncenter: false, // 居中
        alignright: false, // 右对齐
        /* 2.2.1 */
        subfield: false, // 单双栏模式
        preview: true, // 预览
      },
      title: "DIARY",
      model: {
        id: 0,
        title: "",
        happiness: 3,
        learningRec: "",
        lifeRec: "",
        createTime: "",
        userId: 0,
        userImg: "",
      },
    };
  },

  methods: {
    //获取视频详情
    async getDetail(id) {
      try {
        const result = await getDiaryDetail(this.$store.state.token, id);
        if (result.data.code == 0) {
          this.model.title = result.data.data.title;
          this.model.happiness = result.data.data.happiness;
          this.model.learningRec = result.data.data.learning_rec;
          this.model.lifeRec = result.data.data.life_rec;
          this.model.createTime = result.data.data.create_time;
          this.model.userId = result.data.data.user_id;
          this.model.userImg = result.data.data.user_img;
          this.model.id = result.data.data.id;
        }
      } catch (error) {
        console.log(error);
      }
    },
    goTo(path) {
      this.$router.push({ path, query: { id: this.model.id } });
    },
  },
  computed: {
    getToken() {
      return this.$store.state.token;
    },
  },
  mounted() {
    //渲染完成后拿数据
    console.log(this.$route.query.id);
    this.getDetail(this.$route.query.id);
    $(document).ready(function () {
      var tags_p = $("div");
      tags_p.each(function () {
        var x = 6;
        var y = 0;
        var rand = parseInt(Math.random() * (x - y - 1) + y);
        // console.log($(this).attr("class"));
        if ($(this).attr("class") === "main") {
          $(this).addClass("tags" + rand);
        }
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.bold {
  font-weight: bold;
}
.main {
  padding: 50px 5% 50px;
  // text-align: center;
}

.login {
  display: block;
  background-color: #3bb149;
  border: none;
  outline: none;
  width: 80%;
  height: 40px;
  padding: 20px 5% 0;
  margin: 20px auto 0;
  color: #fff;
  border-radius: 20px;
}
.content {
  margin-top: 8px;
  font-size: 33px;
  line-height: 36px;
  font-family: "Arial", "Microsoft YaHei", "黑体", "宋体", sans-serif;
  color: #000000;
  // font-style: italic;
  margin-top: 3px;
}
.tags0 {
  background-image: url('../../common/images/avatar0.png');
}

.tags1 {
  background-image: url('../../common/images/avatar1.png');
}

.tags2 {
  background-image: url('../../common/images/avatar2.png');
}

.tags3 {
  background-image: url('../../common/images/avatar3.png');
}

.tags4 {
  background-image: url('../../common/images/avatar4.png');
}

.tags5 {
  background-image: url('../../common/images/avatar5.png');
}

.bubble {
  padding: 7px 10px;
  color: #333;
  background: #fff;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1);
  position: relative;
  margin: 0 20px 0 5px;
}

.avatar {
  border-radius: 50%;
  margin-left: 30px;
  margin-right: 12px;
  // min-width: 48px;
  width: 75px;
  height: 75px;
  background-size: cover;
  background-image: url('../../common/images/unknown.jpg');
  outline: none;
}

.slide-container-v {
  height: 265px;
  margin-bottom: 15px;
  transform: translateZ(0px);
  border-radius: 2px;
  overflow: hidden;
  box-shadow: 0 2px 9px #ddd;

  .cube-slide-dots {
    .my-dot {
      height: auto;
      font-size: 12px;
      background: none;

      &.active {
        color: #fc9153;
      }
    }
  }
}
</style>
