<template>
  <div>
    <detail-header :title="title"></detail-header>

    <div slot="content">
      <div class="view-wrapper">
        <cube-recycle-list
          class="list"
          :size="size"
          :on-fetch="onFetch"
          :offset="offset"
        >
          <template slot="item" slot-scope="{ data }">
            <div :id="data.id" class="item" @click="handleClick(data)">
              <div
                class="avatar"
                :style="{ backgroundImage: 'url(' + (data.avatar || '') + ')' }"
              ></div>
              <div class="bubble">
                <p>{{ data.msg }}</p>
                <div class="meta">
                  <time class="posted-date" style="font-size:12px; line-height:16px;">{{ data.time }}</time>
                </div>
              </div>
            </div>
          </template>
        </cube-recycle-list>
      </div>
    </div>
    <common-footer></common-footer>

  </div>
</template>

<script type="text/ecmascript-6">
import CubePage from "@/components/cube-page.vue";
// import Mock from '@/data/messages.js'
import cubeRecycleList from "./components/recycle-list.vue";
import DetailHeader from "@/components/Header";
import { getMessageList } from "@/api/getData.js";
import CommonFooter from "@/components/CommonFooter.vue";

const AVATAR_LENGTH = 6;
export default {
  data() {
    return {
      // data
      title: "CHATROOM",
      initTime: new Date().getTime(),
      id: 0,
      size: 50,
      offset: 100,
      messages: [],
    };
  },
  components: {
    CubePage,
    cubeRecycleList,
    CommonFooter,
    DetailHeader,
  },
  methods: {
    getItem(id) {
      const avatar = Math.floor(Math.random() * AVATAR_LENGTH);
      const msg =
        this.messages[Math.floor(Math.random() * this.messages.length)];

      return {
        id,
        avatar: require(`./images/avatar${avatar}.png`),
        msg: msg,
        time: this.dateFormat("YYYY年mm月dd日 HH時MM分SS秒", new Date(
          Math.floor(
            this.initTime +
              id * this.size * 1000 +
              Math.random() * this.size * 1000
          )
        )).toString(),
      };
    },
    onFetch() {
      let items = [];

      return new Promise((resolve) => {
        setTimeout(() => {
          for (let i = 0; i < this.size; i++) {
            items.push(this.getItem(this.id++));
          }
          resolve(items);
        }, 500);
      });
    },
    dateFormat(fmt, date) {
      let ret;
      const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
          );
        }
      }
      return fmt;
    },

    handleClick(data) {
      console.log(data);
    },
    async getMessage() {
      try {
        const result = await getMessageList();
        console.log(result);
        console.log(result.data.code === 0);
        if (result.data.code === 0) {
          this.messages = result.data.data.messages;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.getMessage();
  },
};
</script>

<style scope lang="scss" scoped>
  p{
  font-size:40px;
  line-height:45px;
}
</style>
<style lang="stylus" rel="stylesheet/stylus" scoped>
.view-wrapper {
  position: absolute; 
  top: 54px;
  left: 0;
  bottom: 0;
  width: 100%;
}


.list {
  margin: 0 auto;
  padding: 0;
  border: 1px solid #ddd;
  list-style-type: none;
  text-align: center;
  background: #eee;

  .item {
    display: flex;
    padding: 10px 0;
    width: 100%;
    text-align: left;

    .avatar {
      border-radius: 50%;
      margin-left: 30px;
      margin-right: 12px;
      min-width: 75px;
      width: 75px;
      height: 75px;
      background-image: url('./images/unknown.jpg');
      background-size: cover;
      outline: none;
    }

    p {
      margin: 0;
      word-wrap: break-word;
      font-size: 30px;
    }

    &.tombstone {
      p {
        width: 100%;
        height: 0.5em;
        background-color: #ccc;
        margin: 0.5em 0;
      }
    }

    .bubble {
      padding: 7px 10px;
      color: #333;
      background: #fff;
      box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1);
      position: relative;
      line-height: 23px;
      font-size: 30px;
      // max-width: 420px;
      // min-width: 80px;
      width:100%;
      margin: 0 20px 0 5px;

      &:before {
        content: '';
        border-style: solid;
        border-width: 0 10px 10px 0;
        border-color: transparent #fff transparent transparent;
        position: absolute;
        top: 0;
        left: -10px;
      }
    }

    .meta {
      font-size: 0.8rem;
      color: #999;
      margin-top: 3px;
    }
  }
}
</style>
