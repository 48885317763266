<template>
    <div class= "tab" style="font-size:16px; line-height:20px;" v-show="!fullScreen">
        <header>
            <div class="header">
                <span @click="$router.back(-1)"> <i class="cubeic-back"></i> </span>
                <div class="title" style="font-size:20px; line-height:25px;">
                    {{title}}
                </div>
            </div>
        </header>

    </div>
</template>

<script>
  import {mapGetters, mapMutations, mapActions} from 'vuex'

export default {
      computed:{
    ...mapGetters([
        'fullScreen',
      ])
  },
    props:{
        title: {
        type: String,
        default: '',
        required: true
      }
    }


}
</script>

<style lang="scss" scoped>
.header {
    display: flex;//flex左右布局
    background-color: #07111b;
    padding: 10px 20px;
    color: #fff;
}  
// 返回箭头
.cubeic-back {
    color: #fff;
    margin-right:5px;
}
//视频标题
.title {
    font-size: 16px;
    width: 80%;
    //超出省略
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.tab {
  position: fixed;
  height: fixed;
//   bottom: 0;
  z-index: 999;
  background-color:#fff;
  width: 100%;
  border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
} 
.cube-tab_active {
  color: #3bb149;
}
</style>