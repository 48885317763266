<template>
  <div>
    <div>
      <!-- 日記列表组件 -->
      <diary-list :diaryList="diaryList"></diary-list>
    </div> 
        
    <common-footer></common-footer>
  </div>
</template>

<script>
import DiaryList from "./Component/DiaryList";
import CommonFooter from "@/components/CommonFooter.vue";
import { getDiaryList } from "@/api/getDiaryData.js";

export default {
  //注册组件
  components: {
    DiaryList,
    CommonFooter,
  },
  data() {
    return {
      diaryList: [],
    };
  },
  methods: {
    async getDairyList() {
      try {
        const result = await getDiaryList();
        console.log(result);
        console.log(result.data.code === 0);
        if (result.data.code === 0) {
          this.diaryList = result.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    
  },
  mounted() {
    this.getDairyList();
  },
};
</script>

<style lang="scss" scoped></style>