<template>
  <div class="list-content">
    <div class="list">
      <div v-for="item in diaryList" :key="item.id">
        <cube-button 
          @click="goTo('/calendar')"
          :inline="true"
          v-if="IsNotSameDate(item.create_time)"
          style="line-height: 30px; width: 150px; text-align:center; font-size:15px;"
        >
          {{ getDate(item.create_time, 0, 10) }}
        </cube-button>

        <router-link
          :to="{ path: '/diaryDetail', query: { id: item.id } }"
          class="item"
        >
          <div
            class="avatar"
            :style="{ backgroundImage: 'url(' + (item.user_img || '') + ')' }"
          >
            <br /><br />
            <p class="bold">
              {{ item.user_id }}
            </p>
            <div class="bold">
              &nbsp;{{ getDate(item.create_time, 11, 16) }}
            </div>
          </div>

          <div class="bubble">
            <div class="title">{{ item.title }}</div>
            <div class="content">
              <span class="bold">學習記錄:</span> &nbsp;{{ item.learning_rec }}
            </div>
            <div class="content">
              <span class="bold">生活記錄:</span> &nbsp;{{ item.life_rec }}
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import cubeButtonGroup from "../../../components/cube-button-group.vue";
export default {
  components: { cubeButtonGroup },
  props: {
    diaryList: {
      type: Array,
      required: true,
    },
  },
  methods: {
    goTo(path) {
      this.$router.push(path);
    },
    IsNotSameDate(create_time) {
      if (this.time === "") {
        this.time = create_time.substring(0, 10);
        return true;
      } else if (this.time === create_time.substring(0, 10)) {
        return false;
      } else {
        this.time = create_time.substring(0, 10);
        return true;
      }
    },
    getDate(create_time, beg, end) {
      return create_time.substring(beg, end);
    },
  },
  mounted() {
    this.time = "";
    $(document).ready(function () {
      var tags_p = $("div");
      tags_p.each(function () {
        var x = 6;
        var y = 0;
        var rand = parseInt(Math.random() * (x - y - 1) + y);
        // console.log($(this).attr("class"));
        if ($(this).attr("class") === "list") {
          $(this).addClass("tags" + rand);
        }
      });
    });
  },
};
</script>

<style lang="scss" scoped>
//列表包裹层边距
.list-content {
  // margin-top: 0px;
  padding: 0 0px;
}
//视频包括层
.list {
  // display: flex; //设置flex布局
  flex-wrap: wrap; //换行排列
  justify-content: space-between; //两端对齐
  padding-bottom: 55px;
}
.title {
  //设置超过两行隐藏 start
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: break-all;
  //设置超过两行隐藏 end
  font-size: 35px;
  // height: 26px;
  line-height: 40px;
  margin-top: 10px;
  font-weight: bold;
  color: #2b333b;
}
.bold {
  font-weight: bold;
  color: black;
}
.content {
  margin-top: 8px;
  font-size: 33px;
  line-height: 35px;
  font-family: "Arial", "Microsoft YaHei", "黑体", "宋体", sans-serif;
  color: #000000;
  // font-style: italic;
  margin-top: 3px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.item {
  display: flex;
  padding: 10px 0;
  width: 100%;
  text-align: left;
}
.bubble {
  padding: 7px 10px;
  color: #333;
  background: #fff;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 100%;
  // max-width: 90%;
  // min-width: 50%;
  margin: 0 20px 0 5px;
}

.avatar {
  border-radius: 50%;
  margin-left: 30px;
  margin-right: 12px;
  // min-width: 48px;
  width: 75px;
  height: 75px;
  background-size: cover;
  // background: white;
  background-image: url("../../../common/images/unknown.jpg");
  outline: none;
}

.tags0 {
  background-image: url("../../../common/images/avatar0.png");
}
.tags1 {
  background-image: url("../../../common/images/avatar1.png");
}
.tags2 {
  background-image: url("../../../common/images/avatar2.png");
}
.tags3 {
  background-image: url("../../../common/images/avatar3.png");
}
.tags4 {
  background-image: url("../../../common/images/avatar4.png");
}
.tags5 {
  background-image: url("../../../common/images/avatar5.png");
}
</style>