import axios from '../request'


export const getAudioList = (token) => axios.get("/api/v1/pri/Audio/get_audio_list", {
    headers: { "token": token }
});

export const getImageList = () => axios.get("/api/v1/pub/Image/get_image_list")

export const getContactList = () => axios.get("/api/v1/pub/Contact/get_contact_list")

export const getMessageList = () => axios.get("/api/v1/pub/Message/get_message_list")





