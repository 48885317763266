import axios from '../request'

//記錄接口
export const record = (token, title, happiness, learning_rec, learning_rec_md, life_rec, life_rec_md, id, head_img) => axios.post("/api/v1/pri/RecLife/record", {
    "title": title,
    "happiness": happiness,
    "learning_rec": learning_rec,
    "learning_rec_md": learning_rec_md,
    "life_rec": life_rec,
    "life_rec_md": life_rec_md,
    "user_id": id,
    "user_img": head_img
}, {
    headers: { "token": token }
});

export const getDiaryListByPage = (token, data, query) => axios.post("/api/v1/pri/RecLife/list", {
    "title": data.title,
    "life_rec": data.life_rec,
    "learning_rec": data.learning_rec,
}, {
    params: {
        "page": query.page,
        "limit": query.limit,
    },
    headers: { "token": token }
});


export const del = (token, id) => axios.delete("/api/v1/pri/RecLife/delete", {
    params: {
        "id": id,
    }, 
    headers: { "token": token }
});

export const getDiaryList = () => axios.get("/api/v1/pri/RecLife/get_rec_life_list")

export const update = (token, id, title, happiness, learning_rec, learning_rec_md, life_rec, life_rec_md, create_time, user_id, head_img) => axios.post("/api/v1/pri/RecLife/update", {
    "id": id,
    "title": title,
    "happiness": happiness,
    "learning_rec": learning_rec,
    "learning_rec_md": learning_rec_md,
    "life_rec": life_rec,
    "life_rec_md": life_rec_md,
    "create_time": create_time,
    "user_id": user_id,
    "user_img": head_img
}, {
    headers: { "token": token }
});

export const getDiaryDetail = (token, id) => axios.get("/api/v1/pri/RecLife/get_one", {
    params: {
        "id": id
    },
    headers: {
        "token": token
    }
},
);