<template>
  <div>
    <detail-header :title="title"></detail-header>
    <div slot="content" class= "cool">
      <!-- <div class="view-wrapper"> -->
      <video-player
        class="video-player vjs-custom-skin"
        ref="videoPlayer"
        :playsinline="true"
        :options="playerOptions"
      ></video-player>

      <img-container :imgs="imgs"></img-container>
      <cube-button :light="true" @click="goTo('/upload')">上傳圖片</cube-button>

    </div>
    <!-- </div> -->
    <common-footer></common-footer>
  </div>
</template>

<script>
import CommonFooter from "@/components/CommonFooter";
import DetailHeader from "@/components/Header";
import { getImageList } from "@/api/getData.js";
import ImgContainer from "./components/ImgContainer.vue";

export default {
  components: {
    CommonFooter,
    DetailHeader,
    ImgContainer,
  },
  name: "Video2",
  data() {
    return {
      imgs: [],
      title: "GALLERY",
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", //这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: "http://119.3.129.176/video/6ccff9be9960a79d144f095724510e4a.mp4", //url地址
          },
        ],
        poster: "../../static/images/test.jpg", //你的封面地址
        // width: document.documentElement.clientWidth, //播放器宽度
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
    };
  },
  methods: {
    handleImgsClick(index) {
      this.initialIndex = index;
      const params = {
        $props: {
          imgs: this.imgs,
          initialIndex: "initialIndex",
          loop: false,
        },
        $events: {
          change: (i) => {
            this.initialIndex = i;
          },
        },
      };
      this.$createImagePreview({ ...params }).show();
    },
    async getImage() {
      try {
        const result = await getImageList();
        console.log(result);
        console.log(result.data.code === 0);
        if (result.data.code === 0) {
          this.imgs = result.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    goTo(path) {
      this.$router.push(path);
    },
  },
  mounted() {
    this.getImage();
  },
};
</script>

<style scoped >
.video-js .vjs-icon-placeholder {
  width: 100%;
  height: 100%;
  display: block;
}
.cool {
    padding: 40px 0 50px;
}
</style>

<style lang="stylus" rel="stylesheet/stylus">

</style>