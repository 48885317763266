<template>
  <div>
    <detail-header :title="title"></detail-header>
    <div>
      <!-- 日記列表组件 -->
      <diary-list :diaryList="diaryList"  style="padding-top: 40px;"></diary-list>
    </div> 
    <common-footer></common-footer>
  </div>
</template>

<script>
import DiaryList from "./Component/DiaryList";
import CommonFooter from "@/components/CommonFooter.vue";
import { getDiaryList } from "@/api/getDiaryData.js";
import DetailHeader from "@/components/Header.vue"; 

export default {
  //注册组件
  components: {
    DiaryList,
    CommonFooter,
    DetailHeader
  },
  data() {
    return {
      title: "DATE OF DIARY",
      diaryList: [],
    };
  },
  methods: {
    async getDairyList() {
      try {
        const result = await getDiaryList();
        console.log(result);
        console.log(result.data.code === 0);
        if (result.data.code === 0) {
          this.diaryList = result.data.data;
        }
        this.diaryList.forEach(function(item, index){
          console.log(item);
          // item.status = true;
        })
      } catch (error) {
        console.log(error);
      }
    },
    
  },
  mounted() {
    this.getDairyList();
  },
};
</script>

<style lang="scss" scoped></style>