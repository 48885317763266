<template>
  <el-dialog title="修改头像" :visible.sync="dialogVisible" width="80%" :append-to-body='true' :before-close="handleClose">
    <span id="app-avatar">
        <el-tabs v-model="activeName">
          <el-tab-pane label="動漫" name="first"> 
            <img @click="editAvatar($event)" src="http://119.3.129.176/images/avatar1.jpg" title="點擊更換">
            <img @click="editAvatar($event)" src="http://119.3.129.176/images/avatar2.jpg" title="點擊更換">
            <img @click="editAvatar($event)" src="http://119.3.129.176/images/avatar3.jpg" title="點擊更換">
            <img @click="editAvatar($event)" src="http://119.3.129.176/images/avatar4.jpg" title="點擊更換">
            <img @click="editAvatar($event)" src="http://119.3.129.176/images/avatar5.jpg" title="點擊更換">
            <img @click="editAvatar($event)" src="http://119.3.129.176/images/avatar6.jpg" title="點擊更換">
            <img @click="editAvatar($event)" src="http://119.3.129.176/images/avatar7.jpg" title="點擊更換">
            <img @click="editAvatar($event)" src="http://119.3.129.176/images/avatar8.jpg" title="點擊更換">
            <img @click="editAvatar($event)" src="http://119.3.129.176/images/avatar9.jpg" title="點擊更換">
            <img @click="editAvatar($event)" src="http://119.3.129.176/images/avatar10.jpg" title="點擊更換">
            <img @click="editAvatar($event)" src="http://119.3.129.176/images/avatar11.jpg" title="點擊更換">
          </el-tab-pane>
          <el-tab-pane label="動物" name="second">
            <img @click="editAvatar($event)" src="http://119.3.129.176/images/avatar12.jpg" title="點擊更換">
            <img @click="editAvatar($event)" src="http://119.3.129.176/images/avatar13.jpg" title="點擊更換">
            <img @click="editAvatar($event)" src="http://119.3.129.176/images/avatar14.jpg" title="點擊更換">
            <img @click="editAvatar($event)" src="http://119.3.129.176/images/avatar15.jpg" title="點擊更換">
            <img @click="editAvatar($event)" src="http://119.3.129.176/images/avatar16.jpg" title="點擊更換">
            <img @click="editAvatar($event)" src="http://119.3.129.176/images/avatar17.jpg" title="點擊更換">
            <img @click="editAvatar($event)" src="http://119.3.129.176/images/avatar18.jpg" title="點擊更換">
            <img @click="editAvatar($event)" src="http://119.3.129.176/images/avatar19.jpg" title="點擊更換">
            <img @click="editAvatar($event)" src="http://119.3.129.176/images/avatar20.jpg" title="點擊更換">
            <img @click="editAvatar($event)" src="http://119.3.129.176/images/avatar21.jpg" title="點擊更換">
            <img @click="editAvatar($event)" src="http://119.3.129.176/images/avatar22.jpg" title="點擊更換">
            <img @click="editAvatar($event)" src="http://119.3.129.176/images/avatar23.jpg" title="點擊更換">
            <img @click="editAvatar($event)" src="http://119.3.129.176/images/avatar24.jpg" title="點擊更換">
            <img @click="editAvatar($event)" src="http://119.3.129.176/images/avatar25.jpg" title="點擊更換">

          </el-tab-pane>
        </el-tabs>
    </span>
  </el-dialog>
</template>

<script>
  import { getUserInfo, updateUser } from '@/api/getUserData'
  export default {
    name: "Avatar",
    props: ["content"],
    watch: {
      content(newVal, oldVal) {
        if (newVal) {
          this.dialogVisible = true
        }
      }
    },
    data() {
      return {
        form: {},
        activeName: 'first',
        dialogVisible: false
      }
    },
      computed: {
    getToken() {
      return this.$store.state.token;
    },
  },
    mounted() {
      this.getInfo()
    },
    methods: {
      async signOut() {
      //清除token
      await this.$store.dispatch("clearToken");
      localStorage.removeItem("token");

      //刷新页面
      location.reload();
      // this.goTo('/login');
    },
    goTo(path){
      this.$router.push(path);
    },
      async getInfo() {
      try {
        const result = await getUserInfo(this.getToken);
        if (result.data.code === 0) {
          this.form = result.data.data;
          this.form.pwd = null;
        }
      } catch (error) {
        console.log(error);
      }
    },
      handleClose() {
        this.dialogVisible = false
      },
        
      async editAvatar(event) {
        
      if (this.form.pwd == null || this.form.pwd == "") {
        this.form.pwd = null;
      }
        
       updateUser(this.$store.state.token, null, null, null, event.target.src ,this.form.id).then((res) => {
        
        if (res.data.code === 0) {
          const toast = this.$createToast({
            txt: "頭像已經修改成功啦<br/>請查收",
            type: "correct",
            time: 2000,
          });
          toast.show();
          this.signOut()
          this.goTo('/login')
        } else {
          this.$message.error(res.msg);
        }
      });
      }
    }
  }
</script>

<style scoped>
  #app-avatar img {
    width: 5rem;
    border-radius: 50%;
    margin: .5rem;
    cursor: pointer;
  }

  #app-avatar .layui-tab-item {
    margin-left: 1rem;
  }
</style>
